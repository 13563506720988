/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import PageLoader from 'components/Loaders/PageLoader';
import { useFormik } from 'formik';
import { useVMThunkDispatch } from 'hooks';
import useAsyncRedux from 'hooks/async-redux';
import {
  Button,
  Card, Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { vmActions } from 'store/virtual-machine';
import { IVMEditForm } from 'store/virtual-machine/types/forms.types';
import {
  DiskType,
  IVMState,
  VMStatus,
} from 'store/virtual-machine/types/state.types';
import VirtualMachine from 'store/virtual-machine/types/virtual-machine';
import { moneyFormatter } from 'utils/formatters';
import * as yup from 'yup';
import './style.scss';
import vmService from 'services/api/customer/vm-service';
import ButtonWithConfirm from 'components/UI_Kit/Button/ButtonWithConfirm';
import PageLayout from 'components/Layouts/PageLayout/PageLayout';
import RangeInput from 'components/UI_Kit/Form/RangeInput/RangeInput';

export enum VmEditType {
  NoEdit,
  Name,
  Config,
}

const VMEditPage = () => {
  const [t] = useTranslation();
  const thunkVMDispatch = useVMThunkDispatch();
  const navigate = useNavigate();
  const vmState: IVMState = useSelector((state: RootState) => state.virtualMachine);
  const dispatch = useDispatch();
  const virtualMachine = vmState.single;
  const params = useParams();

  const schema = React.useMemo(() => yup.object().shape({
    vmName: yup.string().required(t('form.error.required')).matches(/^[a-zA-Z0-9-]+$/g, t('vm.params.error.name')),
    ram: yup.number().required(t('form.error.required')),
    cpuCores: yup.number().required(t('form.error.required')),
  }, [['useLocalIp', 'needPublic']]), [t]);

  const [, loading] = useAsyncRedux<string | undefined, void>({
    asyncFunc: (id?: string) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
      if (id) {
        return thunkVMDispatch(vmActions.fetchVMById(id));
      }
      return Promise.resolve();
    },
    immediate: true,
    funcParams: params.id,
  });

  const [handleEdit, editLoading] = useAsyncRedux<IVMEditForm, string | undefined>({
    async asyncFunc(form: IVMEditForm): Promise<string | undefined> {
      if (virtualMachine) {
        const res = await vmService.editVMConfig(virtualMachine.id, VirtualMachine.getEditBody(form, editType));
        return res.data.data.id;
      }
      return undefined;
    },
    errorNote: true,
    successNote: {
      title: t('common.success'),
      message: t('vm.success_change'),
    },
    successHandler(id?: string) {
      if (id) {
        navigate(`/cabinet/servers/${id}`);
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldValue,
    submitCount,
    submitForm,
    initialValues,
  } = useFormik({
    validationSchema: schema,
    onSubmit: handleEdit,
    initialValues: virtualMachine?.editForm || {
      vmName: '',
      cpuCores: 0,
      ram: 0,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const prices = React.useMemo(() => ({
    cpu: values.cpuCores * (vmState.paramLimits?.cpu_cores?.price || 0),
    ram: values.ram * (vmState.paramLimits?.ram?.price || 0),
    publicIp: (virtualMachine?.externalIpAddress && vmState.paramLimits.public_ip?.price) || 0,
    disks: virtualMachine?.disks.reduce<number>((sum, disk) => {
      if (disk.diskType === DiskType.HDD) {
        return sum + (disk.size * (vmState.paramLimits?.hdd_size?.price || 0));
      }
      if (disk.diskType === DiskType.SSD) {
        return sum + (disk.size * (vmState.paramLimits?.ssd_size?.price || 0));
      }
      return sum;
    }, 0) || 0,
  }), [values, vmState]);

  const getRamLimits = React.useCallback((cpuCores) => {
    const limits = {
      max: Math.min(
        Math.round(cpuCores * (vmState.paramLimits.ram?.maxRatio || 999999)),
        Math.min(vmState.paramLimits.ram?.max || 256, vmState.paramLimits.ram?.limit || 256),
      ),
      min: Math.max(
        Math.round(cpuCores * (vmState.paramLimits.ram?.minRatio || 0)),
        vmState.paramLimits.ram?.min || 1,
      ),
    };

    return limits;
  }, [vmState]);

  const [ramLimits, setRamLimits] = React.useState<{ max: number,
    min: number }>(getRamLimits(values.cpuCores));

  React.useEffect(() => {
    setRamLimits(getRamLimits(values.cpuCores));
  }, [vmState.paramLimits]);

  const handleSelectCpu = React.useCallback((value: number) => {
    setFieldValue('cpuCores', value);
    const limits = getRamLimits(value);

    setRamLimits(limits);

    if (values.ram < limits.min) {
      setFieldValue('ram', limits.min);
    } else if (values.ram > limits.max) {
      setFieldValue('ram', limits.max);
    }
  }, [getRamLimits, values]);

  React.useEffect(() => {
    const fields = Object.keys(errors).map((fieldName) => document.querySelector(`[name=${fieldName}]`) as HTMLElement);
    const fieldsSorted = fields.sort((a, b) => a.getBoundingClientRect().y
    - b.getBoundingClientRect().y);
    fieldsSorted[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount]);

  const editType = React.useMemo(() => {
    if (initialValues.cpuCores !== values.cpuCores || initialValues.ram !== values.ram) {
      return VmEditType.Config;
    }
    if (initialValues.vmName !== values.vmName) {
      return VmEditType.Name;
    }
    return VmEditType.NoEdit;
  }, [values]);

  React.useEffect(() => {
    dispatch(vmActions.clearSingleVM());
  }, []);

  return (
    <PageLayout title={t('vm.editing')} className="page-container">
      <h2 className="font-sub-heading pb-2 mb-4">{t('vm.self_plural')}</h2>
      <h3 className="font-button-l mb-3">{t('vm.editing')}</h3>
      {loading ? <PageLoader /> : (
        <div className="form-wrapper d-flex align-items-start">
          <Form noValidate onSubmit={handleSubmit} id="vm-form" className="mb-3 me-4 flex-grow-1">
            <Form.Group controlId="vmName" className="col mb-4">
              <Form.Label as="h3" className="font-bold-body-m mb-3">
                {t('vm.params.name')}
              </Form.Label>
              <Form.Control
                type="text"
                name="vmName"
                autoComplete="false"
                value={values.vmName}
                onChange={handleChange}
                isInvalid={touched.vmName && !!errors.vmName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.vmName}
              </Form.Control.Feedback>
            </Form.Group>
            <h3 className="font-bold-body-m mb-3">
              {t('vm.resources')}
            </h3>
            <Form.Group controlId="cpu-cores" className="mb-3 col">
              <RangeInput
                value={values.cpuCores}
                label={`${t('vm.params.cpu_cores')} ${values.cpuCores}`}
                name="cpuCores"
                min={vmState.paramLimits?.cpu_cores?.min || 1}
                max={vmState.paramLimits?.cpu_cores?.max || 16}
                onChange={handleSelectCpu}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cpuCores}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="ram" className="mb-4 col">
              <RangeInput
                value={values.ram}
                label={`${t('vm.params.ram_2')} ${values.ram} ${t('common.gb')}`}
                name="ram"
                min={ramLimits.min}
                max={ramLimits.max}
                onChange={(value) => {
                  setFieldValue('ram', value);
                }}
              />
            </Form.Group>
          </Form>
          <div className="flex-grow-1 align-self-stretch">
            <Card className="calculated-price me-auto ms-auto">
              <Card.Header className="border-bottom-0">
                <Card.Title className="font-button-l text-primary">{t('vm.calculate_price')}</Card.Title>
              </Card.Header>
              <Card.Body className="pb-4">
                <table className="w-100 font-body-m">
                  <tbody>
                    <tr>
                      <td>
                        {t('vm.params.cpu')}
                        :
                      </td>
                      <td className="text-end">{`${moneyFormatter(prices.cpu)} ${t('common.UZS')}`}</td>
                    </tr>
                    <tr>
                      <td>
                        {t('vm.params.ram_2')}
                        :
                      </td>
                      <td className="text-end">{`${moneyFormatter(prices.ram)} ${t('common.UZS')}`}</td>
                    </tr>
                    <tr>
                      <td>
                        {t('vm.params.disk')}
                        :
                      </td>
                      <td className="text-end">{`${moneyFormatter(prices.disks)} ${t('common.UZS')}`}</td>
                    </tr>
                    {
                        virtualMachine?.externalIpAddress && (
                          <tr>
                            <td>
                              {t('vm.params.external_ip')}
                              :
                            </td>
                            <td className="text-end">{`${moneyFormatter(prices.publicIp)} ${t('common.UZS')}`}</td>
                          </tr>
                        )
                      }
                  </tbody>
                </table>
              </Card.Body>
              <Card.Footer className="border-top-0">
                <div className="d-flex font-button-m justify-content-between align-items-baseline mb-4">
                  {t('common.in_total')}
                  :
                  <span>{`${moneyFormatter(Object.values(prices).reduce((sum, val) => sum + val, 0))} ${t('common.UZS')}`}</span>
                </div>
                {
                    (editType === VmEditType.Name || vmState.single?.status !== VMStatus.Started) ? (
                      <Button
                        className="mb-3 w-100"
                        onClick={() => submitForm()}
                        disabled={editLoading
                          || vmState.single?.blocked || editType === VmEditType.NoEdit}
                      >
                        {t('vm.change_config')}
                      </Button>
                    ) : (
                      <ButtonWithConfirm
                        className="mb-3 w-100"
                        header={t('common.warning')}
                        description={t('vm.server_will_be_rebooted')}
                        variant="primary"
                        disabled={editLoading
                      || vmState.single?.blocked || editType === VmEditType.NoEdit}
                        okTitle={t('common.confirm')}
                        onConfirm={() => submitForm()}
                      >
                        {t('vm.change_config')}
                      </ButtonWithConfirm>

                    )
                  }
                <Link className="btn btn-outline-secondary" to={`../../servers/${vmState.single?.id}`}>{t('common.cancel')}</Link>
              </Card.Footer>
            </Card>
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default VMEditPage;
