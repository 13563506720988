/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import './style.scss';

interface IPasswordInputProps {
  className?: string;
  name?: string;
  autoComplete?: string;
  value: string | undefined;
  error: string | undefined;
  isValid?: boolean;
  isInvalid?: boolean;
  autoFocus?: boolean;
  onChange: (e: ChangeEvent) => void;
}

const PasswordInput: React.FC<IPasswordInputProps> = ({
  className, name, isInvalid, autoFocus, isValid, value, error, onChange, autoComplete,
}) => {
  const [shown, setShown] = React.useState(false);
  const handleShow = () => {
    setShown(true);
  };
  const handleHide = () => {
    setShown(false);
  };
  return (
    <InputGroup hasValidation className="password-input">
      <Form.Control
        className={className}
        type={shown ? 'text' : 'password'}
        autoComplete={autoComplete}
        name={name}
        value={value}
        autoFocus={autoFocus}
        isValid={isValid}
        isInvalid={isInvalid}
        onChange={onChange}
      />
      <div
        className="show-password"
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
      >
        <EyeIcon />
      </div>
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </InputGroup>
  );
};

PasswordInput.defaultProps = {
  className: '',
  name: '',
  isValid: undefined,
  isInvalid: undefined,
  autoComplete: 'on',
};

export default PasswordInput;
