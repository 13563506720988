import React from 'react';
import { Helmet } from 'react-helmet-async';
import './PageLayout.style.scss';

export interface IPageLayoutProps extends React.HTMLProps<HTMLDivElement> {
  title: string
  className?: string
}

const PageLayout:React.FC<IPageLayoutProps> = ({
  className, title, children, ...props
}) => (
  <main className={`page-layout${className ? ` ${className}` : ''}`} {...props}>
    <div className="scrollable-wrapper">
      <Helmet>
        <title>{`${title} | ${process.env.REACT_APP_PROJECT_TITLE}`}</title>
      </Helmet>
      {children}
    </div>
  </main>
);

export default PageLayout;
