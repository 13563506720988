import { updateClassState, updateObjectState } from 'store/reducer.helpers';
import { IVMState, VMStatus } from 'store/virtual-machine/types/state.types';
import VirtualMachine from 'store/virtual-machine/types/virtual-machine';

export function setDiskSizeReducer(state: IVMState, {
  size,
  diskId,
}: { size: number,
  diskId: number }) {
  if (state.single === null) return state;

  const newState = updateObjectState(state, state);
  const newVM = updateClassState<VirtualMachine>(state.single, state.single);
  const diskIndex = newVM.disks.findIndex((_disk) => _disk.id === diskId);

  if (diskIndex !== undefined) {
    const disks = [...newVM.disks];
    const disk = { ...newVM.disks[diskIndex] };

    disk.size += size;
    newVM.disks = disks;
    newVM.disks[diskIndex] = disk;
    newState.single = newVM;
  }

  return newState;
}

export function removeDiskReducer(state: IVMState, diskId: number) {
  if (state.single === null) return state;
  const newState = updateObjectState(state, state);
  const newVM = updateClassState<VirtualMachine>(state.single, state.single);
  const newDisks = newVM.disks.filter((disk) => disk.id !== diskId);
  newVM.disks = newDisks;
  newState.single = newVM;
  return newState;
}

export function setVMStatusReducer(state: IVMState, status: VMStatus) {
  if (state.single === null) return state;
  const newState = updateObjectState(state, state);
  const newVM = updateClassState<VirtualMachine>(state.single, state.single);
  newVM.status = status;
  newState.single = newVM;
  return newState;
}
