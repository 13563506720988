import React from 'react';
// import { ReactComponent as Phone } from 'assets/icons/phone.svg';
// import { ReactComponent as Mail } from 'assets/icons/mail.svg';
// import { ReactComponent as Location } from 'assets/icons/location.svg';
import { ReactComponent as Web } from 'assets/icons/globe.svg';
import { ReactComponent as Telegram } from 'assets/icons/telegram.svg';
import './style.scss';

const Footer: React.FC = () => (
  <footer className="main-footer">
    <a className="text-light text-decoration-none" target="_blank" href="https://pro-data.tech" rel="noreferrer">
      <Web />
      <span>pro-data.tech</span>
    </a>
    {/* <a className="text-light text-decoration-none" href="tel:+998712033022">
      <Phone className="me-1" />
      <span>+998 71 203 30 22</span>
    </a>
    <a className="text-light text-decoration-none" href="mailto:support@pro-data.tech">
      <Mail className="me-1" />
      <span>support@pro-data.tech</span>
    </a> */}
    <a className="text-light text-decoration-none" target="_blank" href="https://t.me/PRO_DATA_Support_Bot" rel="noreferrer">
      <Telegram />
      <span>@PRO_DATA_Support_Bot</span>
    </a>
    {/* <a className="text-light p-2" target="_blank" href="https://yandex.uz/maps/10335/tashkent/house/YkAYdA5hSEECQFprfX54d39mYg==/?ll=69.293152%2C41.296363&z=15.95" rel="noreferrer">
            <Location className="me-1" />
            <span>{t('contacts.address')}</span>
          </a> */}
  </footer>
);

export default Footer;
