import { AnyAction } from 'redux';
import appNotificationHelpers from 'store/app-notifications/helpers';
import { ActionTypes, IAppNotificationState } from 'store/app-notifications/types';

const appNotificationReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IAppNotificationState = {
    unreadCount: 0,
    notifications: [],
  },
  action: AnyAction,
): IAppNotificationState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case ActionTypes.SET_UNREAD_COUNT:
      return { ...state, unreadCount: action.payload };
    case ActionTypes.SET_READ:
      return appNotificationHelpers.setReadById(state, action.payload);
    default:
      return state;
  }
};

export default appNotificationReducer;
