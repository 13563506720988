import React from 'react';
import './style.scss';
import { Fade, Spinner } from 'react-bootstrap';

/**
 *
 * @todo change color animation to provided animated logo
 */

const PageLoader = () => (
  <Fade appear in>
    <div className="page-loader">
      <Spinner animation="border" className="page-loader__logo" />
    </div>
  </Fade>
);

export default PageLoader;
