import { ActionCreator, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import authService from 'services/api/auth-service';
import { RootState } from 'store';
import { ActionTypes, IApiErrorsList } from 'store/api-error/types/api-error';

export const setApiErrors: ActionCreator<AnyAction> = (payload: IApiErrorsList) => ({
  type: ActionTypes.SET_ERRORS,
  payload,
});

export type ThunkApiErrorDispatch = ThunkDispatch<RootState, void, AnyAction>;

export const fetchApiErrors = () => async (dispatch: ThunkApiErrorDispatch) => {
  const { data } = await authService.getErrorsMap();
  const res: IApiErrorsList = {};
  data.data.forEach((dto) => {
    res[dto.code] = {
      ru: dto.ru,
      en: dto.eng,
    };
  });
  dispatch(setApiErrors(res));
};
