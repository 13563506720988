import React from 'react';
import 'assets/styles/main.scss';
import { useApiErrorsThunkDispatch, useUserThunkDispatch } from 'hooks';
import { userActions } from 'store/user';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAsyncFetch from 'hooks/async-fetch';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageLoader from 'components/Loaders/PageLoader';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { UserStatus } from 'store/user/types/state.types';
import { apiErrorActions } from 'store/api-error';

const App = () => {
  const thunkUserDispatch = useUserThunkDispatch();
  const thunkApiErrorDispatch = useApiErrorsThunkDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();

  useAsyncFetch({
    async asyncFunc() {
      await thunkApiErrorDispatch(apiErrorActions.fetchApiErrors());
      await thunkUserDispatch(userActions.fetchProfile());
    },
    immediate: true,
  });

  React.useEffect(() => {
    if (location.pathname === '/') {
      if (user.status === UserStatus.Active) {
        navigate('cabinet', { replace: true });
      }
    }
  }, [location, user]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${process.env.REACT_APP_PROJECT_TITLE}`}</title>
        <meta name="description" data-react-helmet="true" content="Личный кабинет" />
      </Helmet>
      {user.loading && <PageLoader />}
      {
       !user.loading && <Outlet />
      }
    </HelmetProvider>
  );
};

export default App;
