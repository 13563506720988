import { IPagination } from 'components/UI_Kit/TablePagination';

export interface IHandleTableChange {
  (params: {
    pagination?: IPagination, filters?: IFilters, sorting?: ISorting
  }, preventReload?: boolean): void;
}

export interface IFilters {
  [key: string]: any
}

export type ISorting = {
  sortBy: string,
  order: string,
} | null;

export interface ITableQueryParams {
  pagination?: IPagination,
  filters?: IFilters,
  sorting?: ISorting,
}

export function paramsToQuery({
  pagination,
  sorting, filters,
} : ITableQueryParams) {
  let query: string = '';
  if (pagination) {
    query += `&page=${pagination.page}&size=${pagination.pageSize}`;
  }

  if (sorting) {
    query += `&sort=${sorting.sortBy}_${sorting.order}`;
  }

  if (filters) {
    Object.keys(filters).forEach((key) => {
      query += `&${key}=${filters[key]}`;
    });
  }

  return query;
}

export function toQueryParams({
  pagination,
  sorting, filters,
} : ITableQueryParams) {
  const params: { [key: string]: string | number } = {};
  if (pagination) {
    params.page = pagination.page - 1;
    params.size = pagination.pageSize;
  }

  if (sorting) {
    params.sort_by = sorting.sortBy;
    params.order = sorting.order;
  }

  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined && filters[key] !== 'null') {
        params[key] = filters[key];
      }
    });
  }

  return params;
}

export function isJsonArray(str: string) {
  try {
    const parsed = JSON.parse(str);
    return Array.isArray(parsed);
  } catch (e) {
    // If JSON.parse throws an error, the string is not valid JSON
    return false;
  }
}
