import { AnyAction } from 'redux';
import * as helpers from 'store/user/reducer.helpers';
import { User } from 'store/user/types';
import { ActionTypes } from 'store/user/types/state.types';

const userReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: User = new User(),
  { type, payload }: AnyAction,
): User => {
  switch (type) {
    case ActionTypes.SET_USER:
      return payload;
    case ActionTypes.SET_TOKEN:
      return helpers.setTokenReducer(state, payload);
    case ActionTypes.SET_LOADING:
      return helpers.setLoading(state, payload);
    case ActionTypes.CLEAR:
      return helpers.clearUserReducer();
    case ActionTypes.SET_REGION:
      return helpers.setRegionHelper(state, payload);
    case ActionTypes.SET_REGIONS:
      return helpers.setRegionsHelper(state, payload);
    default:
      return state;
  }
  return state;
};

export default userReducer;
