import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { notificationActions } from 'store/notification';
import './style.scss';

const NotificationContainer = () => {
  const notifications = useSelector((state: RootState) => state.notification);
  const dispatch = useDispatch();

  const handleClose = React.useCallback((id) => {
    dispatch(notificationActions.remove(id));
  }, []);

  return (
    <ToastContainer className="notification-container" position="top-end">
      {
        notifications.map((notification) => (
          <Toast
            key={notification.id}
            onClose={() => handleClose(notification.id)}
            bg={notification.variant}
            autohide
            delay={5000}
          >
            <Toast.Header>
              <span>{notification.title}</span>
            </Toast.Header>
            <Toast.Body className="text-white">
              {notification.message}
            </Toast.Body>
          </Toast>
        ))
      }
    </ToastContainer>
  );
};

export default NotificationContainer;
