/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from 'axios';
import MaskedFormControl from 'components/UI_Kit/Form/MaskedFormControl';
import { useFormik } from 'formik';
import { useAppSelector, useUserThunkDispatch } from 'hooks';
import useAsyncFetch from 'hooks/async-fetch';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from 'store/user';
import { User } from 'store/user/types';
import { IOtpAuthDto } from 'store/user/types/api-bodies.types';
import { UserRole } from 'store/user/types/state.types';
import * as yup from 'yup';

const OtpFrom: React.FC = () => {
  const thunkUserDispatch = useUserThunkDispatch();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(userActions.clearUser());
    navigate('/');
  };

  const schema = React.useMemo(() => yup.object().shape({
    totpKey: yup.string().required(t('form.error.required')).length(6, t('form.error.required')),
  }), [t]);

  const [handleLoginConfirm, loading] = useAsyncFetch({
    asyncFunc: async (dto: IOtpAuthDto) => {
      const res = await thunkUserDispatch(userActions.signInConfirm(dto));
      return res;
    },
    successHandler: (res) => {
      if (res instanceof User) {
        if (res.role === UserRole.Customer) {
          navigate('cabinet', { replace: true });
        }
        if (res.role === UserRole.Admin) {
          navigate('admin', { replace: true });
        }
      }
    },
    errorHandler: (error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          setFieldError('totpKey', t('two_fa.wrong_otp'));
        } else {
          // something wrong with server on no connection
          setFieldError('totpKey', t('form.error.error_500'));
        }
      }
    },
  });

  const {
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
    setFieldError,
  } = useFormik({
    validationSchema: schema,
    onSubmit: handleLoginConfirm,
    initialValues: {
      login: user.tempLogin || '',
      password: user.tempPass || '',
      totpKey: '',
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group className="mb-4 pb-2" controlId="otp">
        <Form.Label className="font-bold-body-m">{t('two_fa.otp_long')}</Form.Label>
        <MaskedFormControl
          inputValue={values.totpKey}
          isInvalid={touched.totpKey && !!errors.totpKey}
          name="totpKey"
          type="text"
          maskConfig={{ mask: '000 000' }}
          onChange={(value) => setFieldValue('totpKey', value)}
        />
        <Form.Control.Feedback type="invalid">
          {errors.totpKey}
        </Form.Control.Feedback>

      </Form.Group>
      <Form.Group className="row">
        <div className="col">
          <Button
            className="w-100"
            disabled={loading}
            variant="primary"
            size="lg"
            onClick={handleLogOut}
          >
            {t('auth.signout')}
          </Button>
        </div>
        <div className="col">
          <Button
            className="w-100"
            disabled={loading}
            type="submit"
            size="lg"
            variant="primary"
          >
            {t('common.submit')}
          </Button>
        </div>
      </Form.Group>
    </Form>
  );
};
export default OtpFrom;
