import axios, { Axios, AxiosRequestConfig } from 'axios';

import store from 'store';
import { userActions } from 'store/user';

export interface IResponse {
  error?: number;
  errMessage?: string;
  total?: number;
}
export interface IErrorResponse {
  error?: number;
  errMessage?: string;
}

const apiClient: Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

const authInterceptor = (request: AxiosRequestConfig) => {
  const token = store.getState().user.token || localStorage.getItem('PD-AT');

  if (token && request.url !== 'sign-in/auth' && request.url !== '2fa/validateQrCode') {
    request.headers!.Authorization = `Bearer ${token}`;
  }
  return request;
};

function setAuthInterceptors(client: Axios) {
  client.interceptors.request.use(authInterceptor);
  client.interceptors.response.use(((res) => {
    const remoteVersion = res.headers['frontend-version']
      ? res.headers['frontend-version'] : undefined;
    const localVersion = localStorage.getItem('pdv');
    if (remoteVersion) {
      localStorage.setItem('pdv', remoteVersion);
      if (localVersion !== remoteVersion) {
        window.location.reload();
      }
    }
    return res;
  }), async (error) => {
    const { response } = error;
    if (response && (response.status === 401)) {
      store.dispatch(userActions.clearUser());
    }

    return Promise.reject(error);
  });
}

setAuthInterceptors(apiClient);

export default apiClient;
