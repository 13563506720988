/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button, ButtonProps, FormCheck, Modal, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface IButtonWithConfirmProps {
  className?: string
  dialogClassName?: string
  variant?: string
  okTitle?: string
  cancelTitle?: string
  header?: string
  size?: ButtonProps['size']
  description?: string
  disabled?: boolean
  checkboxConfirm?: string
  checkboxRequired?: boolean
  tooltip?: string
  onConfirm: (confirmCheck?: boolean) => void | Promise<void>
}

const ButtonWithConfirm: React.FC<IButtonWithConfirmProps> = ({
  children,
  className,
  dialogClassName,
  variant,
  okTitle,
  cancelTitle,
  header,
  size,
  description,
  tooltip,
  checkboxConfirm,
  checkboxRequired,
  disabled,
  onConfirm,
}) => {
  const [t] = useTranslation();
  const [confirmShow, setConfirmShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [confirmCheck, setConfirmCheck] = React.useState(false);

  const renderTooltip = React.useCallback((props) => (
    tooltip ? (
      <Tooltip id="button-tooltip" {...props}>
        {tooltip}
      </Tooltip>
    ) : <div />
  ), [tooltip]);

  const handleConfirm = React.useCallback(() => {
    setLoading(true);
    const res = onConfirm(confirmCheck);
    if (res instanceof Promise) {
      res.finally(() => {
        setConfirmShow(false);
        setLoading(false);
      });
    } else {
      setConfirmShow(false);
      setLoading(false);
    }
  }, [onConfirm]);

  return (
    <div className={`d-inline-block ${className || ''}`}>
      <OverlayTrigger placement="top" delay={{ show: 200, hide: 500 }} overlay={renderTooltip}>
        <div>
          <Button
            className="button-with-confirm__button w-100"
            variant={variant || 'primary'}
            size={size}
            disabled={disabled}
            onClick={() => setConfirmShow(true)}
          >
            {children}
          </Button>
        </div>
      </OverlayTrigger>
      <Modal
        show={confirmShow}
        dialogClassName={`button-with-confirm__modal ${dialogClassName || ''}`}
        centered
        onHide={() => setConfirmShow(false)}
      >
        <Modal.Header className="border-bottom-0">
          <Modal.Title>
            {header || t('common.are_you_sure')}
          </Modal.Title>
        </Modal.Header>
        {
            (description || checkboxConfirm) && (
            <Modal.Body>
              {description && <h6>{description || t('common.are_you_sure')}</h6>}
              {
              checkboxConfirm && (
                <FormCheck
                  id="confirm"
                  type="checkbox"
                  label={checkboxConfirm}
                  checked={confirmCheck}
                  onChange={() => setConfirmCheck(!confirmCheck)}
                />
              )
            }
            </Modal.Body>
            )
          }
        <Modal.Footer className="border-top-0">
          <Button
            type="button"
            variant="secondary"
            onClick={() => setConfirmShow(false)}
          >
            {cancelTitle || t('common.cancel')}
          </Button>
          <Button
            type="button"
            variant={variant}
            disabled={loading || (!!checkboxConfirm && checkboxRequired && !confirmCheck)}
            onClick={handleConfirm}
          >
            {okTitle || t('common.yes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ButtonWithConfirm.defaultProps = {
  className: undefined,
  dialogClassName: undefined,
  variant: undefined,
  okTitle: undefined,
  cancelTitle: undefined,
  header: undefined,
  description: undefined,
  size: undefined,
  disabled: undefined,
  checkboxConfirm: undefined,
};

export default ButtonWithConfirm;
