import { useDispatch } from 'react-redux';
import { notificationActions } from 'store/notification';
import { NotifyVariant } from 'store/notification/types';

const useNotification = () => {
  const dispatch = useDispatch();
  return {
    error: (title?: string, message?: string) => dispatch(notificationActions.add({
      variant: NotifyVariant.Error,
      title,
      message,
    })),
    success: (title?: string, message?: string) => dispatch(notificationActions.add({
      variant: NotifyVariant.Success,
      title,
      message,
    })),
  };
};

export default useNotification;
