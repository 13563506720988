import { VmEditType } from 'pages/customer/VM/vm.edit';
import { INetwork } from 'store/network/types/network';
import VMNetwork from 'store/network/types/vm-network';
import {
  IVMBody, IVMCreateReqBody, IVMEditReqBody,
} from 'store/virtual-machine/types/api-bodies.types';
import { IVMCreateForm, IVMEditForm } from 'store/virtual-machine/types/forms.types';
import {
  DiskType, IDisk, VMStatus,
} from 'store/virtual-machine/types/state.types';
import toBase64 from 'utils/to-base-64';

class VirtualMachine {
  id: string = '';

  externalId: string = '';

  vmName: string = '';

  externalIpAddress: string = '';

  status: VMStatus = VMStatus.Fail;

  localDiskSize: number = 0;

  osName: string = '';

  region: {
    name: string;
    regionId: string;
  } = {
      name: '',
      regionId: '',
    };

  ram: number = 0;

  cpuCores: number = 0;

  diskType: DiskType = DiskType.HDD;

  diskSize: number = 0;

  localIp: string = '';

  vmTemplateId: number = 0;

  localNetworks: INetwork[] = [];

  publicNetworks: INetwork[] = [];

  disks: IDisk[] = [];

  user?: string;

  password?: string;

  blocked: boolean;

  totalSize: number;

  hasSSH: boolean;

  sshAvail: boolean;

  publicKeyHash: string | null = null;

  autoGeneratedKey: boolean;

  agentAvailable: boolean;

  customImageId: number | null = null;

  constructor(body: IVMBody) {
    this.id = body.id;
    this.vmName = body.vmName;
    this.externalIpAddress = body.externalIpAddress;
    this.status = body.status;
    this.localDiskSize = body.localDiskSize;
    this.osName = body.osName;
    this.ram = body.ram;
    this.cpuCores = body.cpuCores;
    this.diskType = body.diskType;
    this.localIp = body.localIp;
    this.disks = body.disks;
    this.user = body.user;
    this.password = body.password;
    this.blocked = body.blocked;
    this.totalSize = body.totalVmSize || 0;
    this.hasSSH = body.hasSSH;
    this.sshAvail = body.sshAvail;
    this.autoGeneratedKey = body.autoGenerated;
    this.publicKeyHash = body.publicKeyHash;
    this.agentAvailable = body.agentAvailable;
    this.customImageId = body.customImage?.id || null;
    body.nets.forEach((net) => {
      const network = new VMNetwork(net);
      if (network.local) {
        this.localNetworks.push(network);
      } else {
        this.publicNetworks.push(network);
      }
    });
  }

  get bootDisk() {
    return this.disks.find((disk) => disk.boot);
  }

  get creationForm(): IVMCreateForm {
    return {
      vmName: this.vmName,
      ram: this.ram,
      cpuCores: this.cpuCores,
      diskType: this.diskType,
      localDiskSize: this.diskSize,
      localIp: this.localIp,
      vmTemplateId: this.vmTemplateId,
      useLocalIp: !!this.localIp,
      needPublic: false,
      mask: `/${this.localIp.split('/')[1]}` || '/',
      isNewNetwork: true,
      existingNetworkId: '',
      localNetworkName: '',
      authorizeSsh: this.hasSSH,
      generateNewKeys: true,
      customImageFilePath: '',
      customImageId: null,
      needDrivers: false,
      cronName: '',
      cronParam: '',
    };
  }

  get editForm(): IVMEditForm {
    return {
      vmName: this.vmName || '',
      cpuCores: this.cpuCores || 0,
      ram: this.ram || 0,
    };
  }

  static async getCreateBody(form: IVMCreateForm): Promise<IVMCreateReqBody> {
    return {
      vmName: form.vmName,
      ram: form.ram as number,
      cpuCores: form.cpuCores as number,
      diskType: form.diskType as DiskType,
      localDiskSize: form.localDiskSize as number,
      localIp: form.useLocalIp ? form.localIp : null,
      mask: form.useLocalIp ? form.mask : null,
      vmTemplateId: form.vmTemplateId as number,
      needPublic: form.needPublic,
      authorizePass: true,
      localNetId: form.isNewNetwork ? undefined : Number(form.existingNetworkId),
      localNetName: form.isNewNetwork ? form.localNetworkName : undefined,
      authorizeSsh: form.authorizeSsh,
      publicKey: form.sshKeyEncoded ? await toBase64(form.sshKeyEncoded) : undefined,
      customImageFilePath: form.customImageFilePath || null,
      customImageId: form.customImageId,
      needDrivers: form.needDrivers,
      cronName: form.cronName || null,
      cronParam: form.cronParam || null,
    };
  }

  static getEditBody(form: IVMEditForm, editType: VmEditType) : IVMEditReqBody {
    const dto: IVMEditReqBody = {};
    dto.vmName = form.vmName;
    if (editType === VmEditType.Name) {
      dto.cpuCores = null;
      dto.ram = null;
    } else {
      dto.cpuCores = form.cpuCores;
      dto.ram = form.ram;
    }
    return dto;
  }
}

export default VirtualMachine;
