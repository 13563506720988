import React from 'react';
import logo from 'assets/images/logo-2-color.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { UserRole } from 'store/user/types/state.types';
import { useUserThunkDispatch } from 'hooks';
import { FormGroup } from 'react-bootstrap';
import InputDropdown from 'components/UI_Kit/Form/InputDropdown';
import authService from 'services/api/auth-service';
import { userActions } from 'store/user';
import useAsyncFetch from 'hooks/async-fetch';
import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
import ResourcesLimit from 'components/VirtualMachine/ResourcesLimits';
import UserCard from 'components/UserCard/UserCard';
import AppNotificationContainer from 'components/AppNotifications/AppNotificationContainer';

const Header:React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const userThunkDispatch = useUserThunkDispatch();
  const navigate = useNavigate();

  useAsyncFetch({
    asyncFunc: () => userThunkDispatch(userActions.getRegions),
    immediate: true,
  });

  const [handleChangeRegion] = useAsyncFetch<[string | null], void>({
    async asyncFunc(regionId) {
      if (regionId) {
        await authService.setRegion(regionId);
        navigate('/');
        await userThunkDispatch(userActions.fetchProfile());
      }
    },
    errorNote: true,
  });

  return (
    <header className="app-header">
      <h1 className="header-logo">
        <Link to="/">
          <img src={logo} width={150} alt="PRO-DATA" />
        </Link>
      </h1>
      <FormGroup className="me-4 pe-2">
        <InputDropdown
          className="regions-selector"
          value={user.region?.id.toString() || null}
          data={user.regions.map((region) => ({
            value: region.id.toString(),
            label: region.name,
          }))}
          onSelect={handleChangeRegion}
        />
      </FormGroup>
      {user.role === UserRole.Customer && <ResourcesLimit />}
      <AppNotificationContainer />
      <UserCard user={user} />
    </header>
  );
};

export default Header;
