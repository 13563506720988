import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store';
import authService from 'services/api/auth-service';
import {
  IChangePasswordBody, ILoginBody, IOtpAuthDto, IRegisterBody, IUserErrorDto,
} from 'store/user/types/api-bodies.types';
import { ActionCreator, AnyAction } from 'redux';
import { ActionTypes, IRegion, UserStatus } from 'store/user/types/state.types';
import { User } from 'store/user/types';
import { userActions } from 'store/user';

export const setToken: ActionCreator<AnyAction> = (token: string) => ({
  type: ActionTypes.SET_TOKEN,
  payload: token,
});

export const setUser: ActionCreator<AnyAction> = (user: User) => ({
  type: ActionTypes.SET_USER,
  payload: user,
});

export const setLoading: ActionCreator<AnyAction> = (value: boolean) => ({
  type: ActionTypes.SET_LOADING,
  payload: value,
});

export const setRegion: ActionCreator<AnyAction> = (region: IRegion) => ({
  type: ActionTypes.SET_REGION,
  payload: region,
});

export const setRegions: ActionCreator<AnyAction> = (regions: IRegion[]) => ({
  type: ActionTypes.SET_REGIONS,
  payload: regions,
});

export const clearUser: ActionCreator<AnyAction> = () => ({
  type: ActionTypes.CLEAR,
});

export type ThunkUserDispatch = ThunkDispatch<RootState, void, AnyAction>;

export const fetchProfile = () => async (dispatch: ThunkUserDispatch) => {
  const localToken = localStorage.getItem('PD-AT');
  if (localToken) {
    dispatch(setLoading(true));
    authService.getProfile()
      .then(({ data }) => {
        const user = new User(data.data);
        const token = localStorage.getItem('PD-AT');
        if (token) {
          user.token = token;
        }
        dispatch(setUser(user));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  } else {
    dispatch(setLoading(false));
  }
};

// eslint-disable-next-line max-len
export const signIn = (body: ILoginBody) => async (dispatch: ThunkUserDispatch): Promise<User | IUserErrorDto> => {
  const { data } = await authService.logIn(body);
  // console.log('login');
  // const data: IUserLoginResBody = {
  //   data: 'WrongLoginPassword',
  //   errMessage: '2',
  // };
  if (typeof data.data === 'object') {
    if (data.data.has2fa) {
      const user = new User();
      user.tempPass = body.password;
      user.tempLogin = body.login;
      user.state = '2fa';
      dispatch(setUser(user));
      dispatch(setLoading(false));
      return user;
    }

    const user = new User(data.data);
    if (user.status === UserStatus.New) {
      user.tempPass = body.password;
      user.state = 'expired';
    } else {
      localStorage.setItem('PD-AT', user.token || '');
    }
    dispatch(setUser(user));
    dispatch(setLoading(false));
    return user;
  }
  dispatch(setLoading(false));
  return {
    errorType: data.data,
    message: data.errMessage || '',
  };
};

// eslint-disable-next-line max-len
export const signInConfirm = (body: IOtpAuthDto) => async (dispatch: ThunkUserDispatch): Promise<User | IUserErrorDto> => {
  const { data } = await authService.loginConfirm(body);
  // console.log('login');
  // const data: IUserLoginResBody = {
  //   data: 'WrongLoginPassword',
  //   errMessage: '2',
  // };
  // eslint-disable-next-line no-debugger
  debugger;
  if (typeof data.data === 'object') {
    const user = new User(data.data);
    if (user.status === UserStatus.New) {
      user.tempPass = body.password;
      user.state = 'expired';
    } else {
      localStorage.setItem('PD-AT', user.token || '');
    }
    dispatch(setUser(user));
    dispatch(setLoading(false));
    return user;
  }
  dispatch(setLoading(false));
  return {
    errorType: data.data,
    message: data.errMessage || '',
  };
};

// eslint-disable-next-line max-len
export const changePassword = (body: IChangePasswordBody) => async (dispatch: ThunkUserDispatch): Promise<User | IUserErrorDto> => {
  const { data } = await authService.changePassword(body);

  if (typeof data.data === 'object') {
    if (data.data.has2fa) {
      const user = new User();
      user.tempPass = body.password;
      user.tempLogin = body.login;
      user.state = '2fa';
      dispatch(setUser(user));
      dispatch(setLoading(false));
      return user;
    }

    const user = new User(data.data);
    if (user.status === UserStatus.New) {
      user.tempPass = body.password;
      user.state = 'expired';
    } else {
      localStorage.setItem('PD-AT', user.token || '');
    }
    dispatch(setUser(user));
    dispatch(setLoading(false));
    return user;
  }
  dispatch(setLoading(false));
  return {
    errorType: data.data,
    message: data.errMessage || '',
  };
};

export const signUp = (body: IRegisterBody) => async (dispatch: ThunkUserDispatch) => {
  const { data } = await authService.register(body);
  const user = new User(data.data);
  // localStorage.setItem('PD-AT', user.token || '');
  dispatch(setUser(user));
};

export const signOut = async (dispatch: ThunkUserDispatch) => {
  await authService.logOut();
  dispatch(userActions.clearUser);
};

export const getRegions = async (dispatch: ThunkUserDispatch) => {
  const { data } = await authService.getUserRegions();
  dispatch(setRegions(data.data.regions));
};
