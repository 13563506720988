import { ThunkUserDispatch } from 'store/user/actions';
import { AppDispatch, RootState } from 'store';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { ThunkVMDispatch } from 'store/virtual-machine/actions';
import { ThunkApiErrorDispatch } from 'store/api-error/actions';
import { ThunkAppNotificationDispatch } from 'store/app-notifications/actions';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useUserThunkDispatch = () => useDispatch<ThunkUserDispatch>();
export const useVMThunkDispatch = () => useDispatch<ThunkVMDispatch>();
export const useApiErrorsThunkDispatch = () => useDispatch<ThunkApiErrorDispatch>();
export const useAppNotificationDispatch = () => useDispatch<ThunkAppNotificationDispatch>();

export { default as useNotification } from './notification';
export { default as useTableParams } from './table-params';
