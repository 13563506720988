import { IAppNotificationState } from 'store/app-notifications/types';

const appNotificationHelpers = {
  setReadById(state: IAppNotificationState, id: number): IAppNotificationState {
    const notification = state.notifications.find((notify) => notify.id === id);
    if (notification) {
      notification.wasRead = true;
      return {
        ...state,
        notifications: [...state.notifications],
      };
    }
    return state;
  },
};

export default appNotificationHelpers;
