import { AnyAction } from 'redux';
import { ActionTypes, IApiErrorsList } from 'store/api-error/types/api-error';

const apiErrorsReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IApiErrorsList = {},
  { type, payload }: AnyAction,
): IApiErrorsList => {
  switch (type) {
    case ActionTypes.SET_ERRORS:
      return payload;
    default:
      return state;
  }
};

export default apiErrorsReducer;
