/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FormRange, { FormRangeProps } from 'react-bootstrap/esm/FormRange';
import { IMaskInput } from 'react-imask';
import './RangeInput.style.scss';
import { FormControl } from 'react-bootstrap';

interface IRangeInput extends Omit<FormRangeProps, 'onChange'> {
  label: string
  error?: string
  onChange: (value: number) => void
}

const RangeInput:React.FC<IRangeInput> = ({
  onChange, value, label, min, max, error, ...props
}) => {
  const [inputValue, setInputValue] = React.useState<string>(value?.toString() || '');
  const [isValidInput, setIsValidInput] = React.useState(true);

  const handleSelectRange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    // setInputValue(e.target.value?.toString() || '');
    // const newVal = Number(val);
    // if (newVal > 0) {
    onChange(Number(e.target.value));
    // }
  };
  const handleInput = (val: unknown) => {
    setInputValue(val?.toString() || '');
    if (isValidInput === false) {
      setIsValidInput(true);
    }
    if (val === '') return;
    const newVal = Number(val as string);
    // if (newVal >= Number(min) && newVal <= Number(max)) {
    onChange(newVal);
    // }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleBlur = () => {
    onChange(Number(value));
    setIsValidInput(Number(value) >= Number(min) && Number(value) <= Number(max));
    setInputValue(value?.toString() || '');
  };

  React.useEffect(() => {
    if (value) {
      setInputValue(value?.toString() || '0');
    }
  }, [value]);

  return (
    <div className="range-input">
      <div className="range-input__container">
        <label htmlFor={props.name || props.id}>{label}</label>
        <FormRange
          className="range-input__range"
          value={value}
          min={Number(min) || undefined}
          max={Number(max) || undefined}
          {...props}
          onChange={handleSelectRange}
        />
        <FormControl isInvalid={!!error} hidden />
        <IMaskInput
          className="range-input__input"
        // mask={new Array(max?.toString().length).fill('0').join('')}
          mask={Number}
        // max={Number(max)}
          scale={0}
          value={inputValue}
          onAccept={handleInput}
          data-size={max?.toString().length}
          data-invalid={!isValidInput}
          onBlur={handleBlur}
        />
      </div>
      <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
    </div>
  );
};

export default RangeInput;
