import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationUZ from './locales/uz/translation.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem('pdlang') || (navigator.language.includes('en') ? 'en' : 'ru'),
    resources: {
      en: {
        translation: translationEN,
      },
      ru: {
        translation: translationRU,
      },
      uz: {
        translation: translationUZ,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
