import InputDropdown from 'components/UI_Kit/Form/InputDropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import authService from 'services/api/auth-service';
import { useAppSelector } from 'hooks';

// eslint-disable-next-line react/prop-types
const LanguageSelector:React.FC<React.HTMLProps<HTMLDivElement>> = ({ className }) => {
  const [, i18n] = useTranslation();
  const user = useAppSelector((state) => state.user);
  const langs = React.useMemo(() => [
    {
      value: 'ru',
      label: 'RU',
    },
    {
      value: 'en',
      label: 'EN',
    },
  ], []);

  const handleSelect = (value: string | null) => {
    if (value) {
      i18n.changeLanguage(value);
      localStorage.setItem('pdlang', value);
      authService.setLanguage(value);
    }
  };

  React.useEffect(() => {
    if (user.language && user.language !== i18n.language) {
      i18n.changeLanguage(user.language);
      localStorage.setItem('pdlang', user.language);
    }

    if (user.token && user.language === null) {
      authService.setLanguage(i18n.language);
    }
  }, [user]);
  return (
    <InputDropdown
      className={`language-selector${className ? ` ${className}` : ''}`}
      value={i18n.language}
      data={langs}
      onSelect={handleSelect}
    />
  );
};

export default LanguageSelector;
