import { Axios } from 'axios';
import apiClient from 'services/api/axios';
import { IAppNotificationsRes, ITelegramAttachDto } from 'store/app-notifications/types';

class NotificationService {
  api: Axios;

  constructor(api: Axios) {
    this.api = api;
  }

  getNotifications() {
    return this.api.get<IAppNotificationsRes>('/user/notifications');
  }

  setRead(id: number) {
    return this.api.get(`/user/notifications/${id}`);
  }

  attachTelegram(dto: ITelegramAttachDto) {
    return this.api.post('telegram/checkCode', dto);
  }
}

export default new NotificationService(apiClient);
