/* eslint-disable consistent-return */
/* eslint-disable import/export */
import axios, { AxiosError } from 'axios';
import useNotification from 'hooks/notification';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from 'services/i18n';
import store from 'store';

export default function useAsyncFetch<T extends any[], K>(
  props: {
    asyncFunc: (...params: T) => Promise<void | K>,
    initialState?: K
    successHandler?: (state?: K) => K | void,
    errorHandler?: (error: AxiosError, errMessage: string, ...params: T) => void,
    errorNote?: boolean,
    successNote?: string,
  },
): [(...params: T) => Promise<K>, boolean, K, React.Dispatch<React.SetStateAction<K>>];

export default function useAsyncFetch<K>(
  props: {
    asyncFunc: () => Promise<void | K>,
    initialState?: K
    successHandler?: (state?: K) => void,
    errorHandler?: (error: AxiosError, errMessage: string) => void,
    errorNote?: boolean,
    successNote?: string,
    immediate?: boolean
  },
): [() => Promise<K>, boolean, K, React.Dispatch<React.SetStateAction<K>>];

export default function useAsyncFetch<T extends any[], K>(
  props: {
    asyncFunc: (...params: T) => Promise<void | K>,
    initialState?: K
    successHandler?: (state?: K) => void,
    errorHandler?: (error: AxiosError, errMessage: string, ...params: T) => void,
    errorNote?: boolean,
    successNote?: string,
    immediate: true,
    initialParams: T
  },
): [(...params: T) => Promise<K>, boolean, K, React.Dispatch<React.SetStateAction<K>>];

export default function useAsyncFetch({
  asyncFunc,
  initialState,
  successHandler,
  errorHandler,
  errorNote,
  successNote,
  immediate,
  initialParams,
}: any) {
  const [loading, setLoading] = React.useState<boolean>(!!immediate);
  const [state, setState] = React.useState(initialState);
  const mountedRef = React.useRef(true);
  const notification = useNotification();
  const navigate = useNavigate();

  const execute = React.useCallback((...params) => {
    setLoading(true);
    return asyncFunc(...params)
      .then((res: any) => {
        if (!mountedRef.current) return;

        if (successNote) {
          notification.success('', successNote);
        }

        if (successHandler) {
          successHandler(res);
        }
        if (initialState !== undefined) {
          setState(res);
        }
        return res;
      })
      .catch((err: any) => {
        if (!mountedRef.current) return;
        const errorObj = store.getState().apiErrors[err.response?.data?.error as number];

        const errorMessage = errorObj ? errorObj[i18n.language as 'ru' | 'en']
          : (err.response?.data.errMessage || i18n.t('form.error.error_500'));
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            navigate('/');
            return;
          }

          if (errorNote) {
            notification.error(err.response?.data.error, errorMessage);
          }
        }
        if (errorHandler) {
          errorHandler(err, errorMessage, ...params);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [asyncFunc, initialParams]);

  React.useEffect(() => {
    if (immediate) {
      if (initialParams) {
        execute(...initialParams);
      } else execute();
    }
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return [
    execute,
    loading,
    state,
    setState,
  ];
}
