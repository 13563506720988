import { AnyAction } from 'redux';
import { ActionTypes, INotificationParams } from 'store/notification/types';

// eslint-disable-next-line import/prefer-default-export
export const add = (params: INotificationParams): AnyAction => ({
  type: ActionTypes.ADD,
  payload: params,
});

export const remove = (id: string): AnyAction => ({
  type: ActionTypes.REMOVE,
  payload: id,
});
