/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-await-in-loop */
import { IOSTemplateDto } from 'components/OSTemplates/types/dtos';
import { ActionCreator, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import backupService from 'services/api/customer/backup-service';
import osTemplateService from 'services/api/customer/os-template-service';
import vmService from 'services/api/customer/vm-service';
import { RootState } from 'store';
import { IRegion } from 'store/user/types/state.types';
import { vmActions } from 'store/virtual-machine';
import {
  IDiskAddBody, IDiskIncreaseBody, INetworkEditBody, IOSTypeDto, IVMCreateReqBody,
} from 'store/virtual-machine/types/api-bodies.types';
import {
  ActionTypes, DiskType, IParamLimits,
  IVMState, IVMTemplate, LimitedVMParam,
  VMStateAction, VMStatus,
} from 'store/virtual-machine/types/state.types';
import VirtualMachine from 'store/virtual-machine/types/virtual-machine';

export const setSingleVM: ActionCreator<AnyAction> = (payload: VirtualMachine) => ({
  type: ActionTypes.SET_SINGLE_VM,
  payload,
});

export const setVmStatus: ActionCreator<AnyAction> = (payload: VMStatus) => ({
  type: ActionTypes.SET_STATUS,
  payload,
});

export const clearSingleVM: ActionCreator<AnyAction> = () => ({
  type: ActionTypes.CLEAR_SINGLE_VM,
});

export const setListOfVMs: ActionCreator<AnyAction> = (payload: VirtualMachine[]) => ({
  type: ActionTypes.SET_LIST,
  payload,
});

export const setRegions: ActionCreator<AnyAction> = (payload: IRegion) => ({
  type: ActionTypes.SET_REGIONS,
  payload,
});

export const setVMTemplates: ActionCreator<AnyAction> = (payload: IVMTemplate[]) => ({
  type: ActionTypes.SET_TEMPLATES,
  payload,
});

export const setCustomTemplates: ActionCreator<AnyAction> = (payload: IOSTemplateDto[]) => ({
  type: ActionTypes.SET_CUSTOM_TEMPLATES,
  payload,
});

export const setOSTypes: ActionCreator<AnyAction> = (payload: IOSTypeDto[]) => ({
  type: ActionTypes.SET_OS_TYPES,
  payload,
});

export const setTemplateMaxSize: ActionCreator<AnyAction> = (payload: number) => ({
  type: ActionTypes.SET_TEMPLATE_MAX_SIZE,
  payload,
});

export const setPramLimits: ActionCreator<AnyAction> = (payload: IParamLimits[]) => ({
  type: ActionTypes.SET_PARAM_LIMITS,
  payload,
});

export const setDiskSize: ActionCreator<AnyAction> = (payload: { size: number,
  diskId: number }) => ({
  type: ActionTypes.SET_DISK_SIZE,
  payload,
});

export const removeDisk: ActionCreator<AnyAction> = (payload: number) => ({
  type: ActionTypes.DISK_DELETE,
  payload,
});

export const asBootDiskAction: ActionCreator<AnyAction> = (payload: number) => ({
  type: ActionTypes.AS_BOOT_DISK,
  payload,
});

export const setDiskSpeed: ActionCreator<AnyAction> = (payload: IVMState['diskSpeed']) => ({
  type: ActionTypes.SET_DISK_SPEED,
  payload,
});

export const setNetworkParams: ActionCreator<AnyAction> = (payload: {
  networkId: number,
  ip: string }) => ({
  type: ActionTypes.EDIT_NETWORK,
  payload,
});

export type ThunkVMDispatch = ThunkDispatch<RootState, void, AnyAction>;

export const fetchVMList = () => async (dispatch: ThunkVMDispatch) => {
  const { data } = await vmService.getVMList();
  const VMs = data.data.vms.map((vm) => new VirtualMachine(vm));
  dispatch(setListOfVMs(VMs));
  return VMs;
};

export const fetchVMById = (id: string) => async (dispatch: ThunkVMDispatch) => {
  const { data } = await vmService.getVMById(id);
  dispatch(setSingleVM(new VirtualMachine(data.data)));
};

export const fetchVMTemplates = () => async (dispatch: ThunkVMDispatch) => {
  const { data } = await vmService.getTemplates();

  const templates: IVMTemplate[] = data.data.vmTemplates.map((template) => ({
    vmTemplateId: template.vmTemplateId,
    osName: template.osName,
    description: template.description,
    custom: template.custom,
    sshAvail: template.sshAvail,
    osTypeId: template.osTypeId,
    defaultDiskSize: template.defaultDiskSize,
  }));

  dispatch(setVMTemplates(templates));
  return templates;
};

export const fetchCustomTemplates = () => async (dispatch: ThunkVMDispatch) => {
  const { data } = await osTemplateService.getTemplates();

  dispatch(setCustomTemplates(data.data.customTemplateDTOS));
  return data.data.customTemplateDTOS;
};

export const fetchTemplateMaxSize = () => async (dispatch: ThunkVMDispatch) => {
  const { data } = await osTemplateService.getTemplateMaxSize();

  dispatch(setTemplateMaxSize((Number(data.data.val) || 0)));
};

export const fetchOSTypes = () => async (dispatch: ThunkVMDispatch) => {
  const { data } = await vmService.getOSTypes();
  dispatch(setOSTypes(data.data.vmOSTypesDTOList));
};

export const fetchParamLimits = (
  vmTemplateId?: number,
) => async (dispatch: ThunkVMDispatch, getState: () => RootState) => {
  const { data } = await vmService.getVMParamLimits(vmTemplateId);
  const regionId = getState().user.region?.id;

  let backupLimit: number = 0;
  if (regionId) {
    try {
      const backups = await backupService.getLimits({
        region_id: regionId,
        isNewSchedule: false,
        limitSize: 0,
      });
      backupLimit = backups.data.data;
    } catch (error) {
      console.log(error);
    }
  }
  const params: IParamLimits = data.data.resources
    .reduce((total, param) => ({
      ...total,
      [param.resourceName]: {
        max: (param.unitLimit),
        min: param.minUnitVal,
        price: param.priceForUnit,
        minRatio: param.minRatio,
        maxRatio: param.maxRatio,
        limit: param.totalLimit,
      },
    }), {});
  params[LimitedVMParam.BackupQuota] = {
    max: 0,
    min: 0,
    price: 0,
    limit: backupLimit || 0,
  };
  dispatch(setPramLimits(params));
};

export const fetchBackupQuotas = (limitSize?: number, isNewSchedule?: boolean) => async (
  dispatch: ThunkVMDispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const regionId = state.user.region?.id;
  const params = state.virtualMachine.paramLimits;
  let backupLimit: number = 0;
  if (regionId) {
    const backups = await backupService.getLimits({
      region_id: regionId,
      limitSize: limitSize || 0,
      isNewSchedule: isNewSchedule || false,
    });
    backupLimit = backups.data.data;
  }

  if (!limitSize) {
    params[LimitedVMParam.BackupQuota] = {
      max: 0,
      min: 0,
      price: 0,
      limit: backupLimit || 0,
    };
    dispatch(setPramLimits({ ...params }));
  }
  return backupLimit;
};

export const createVM = async (body: IVMCreateReqBody) => {
  const res = await vmService.createVM(body);
  return res.data.data;
};

export const calculateCost = async (body: IVMCreateReqBody) => {
  const res = await vmService.calculateCost(body);
  return res.data;
};

export const addDisk = (
  vmId: string,
  needReboot: boolean,
  body: IDiskAddBody,
) => async (dispatch: ThunkVMDispatch) => {
  const res = await vmService.addDisk(vmId, needReboot, body);
  const vm = new VirtualMachine(res.data.data);
  dispatch(setSingleVM(vm));
};

export const increaseDisk = (params: {
  vmId: string,
  diskId: number,
  needReboot: boolean,
  body: IDiskIncreaseBody,
}) => async (dispatch: ThunkVMDispatch) => {
  await vmService.increaseDisk(params.vmId, params.diskId, params.needReboot, params.body);
  dispatch(setDiskSize({
    diskId: params.diskId,
    size: params.body.increaseSize,
  }));
};

export const deleteDisk = (params: {
  vmId: string,
  diskId: number,
}) => async (dispatch: ThunkVMDispatch) => {
  await vmService.deleteDisk(params.vmId, params.diskId);
  dispatch(removeDisk(params.diskId));
};

export const asBootDisk = (params: {
  vmId: string,
  diskId: number,
}) => async (dispatch: ThunkVMDispatch) => {
  await vmService.asBootDisk(params.vmId, params.diskId);
  dispatch(asBootDiskAction(params.diskId));
};

export const editNetwork = (
  vmId: string,
  networkId: number,
  body: INetworkEditBody,
) => async (dispatch: ThunkVMDispatch) => {
  await vmService.editNetwork(vmId, networkId, body);
  dispatch(setNetworkParams({
    ip: `${body.ip}${body.mask}`,
    networkId,
  }));
};

export const toggleVm = (
  toggleAction: VMStateAction,
  vmId: string,
) => async (dispatch: ThunkVMDispatch, getState: () => RootState) => {
  const oldStatus = getState().virtualMachine.single?.status;

  if (toggleAction === VMStateAction.Start) {
    dispatch(vmActions.setVmStatus(VMStatus.Starting));
  }
  if (toggleAction === VMStateAction.Stop) {
    dispatch(vmActions.setVmStatus(VMStatus.Stopping));
  }
  if (toggleAction === VMStateAction.Reboot) {
    dispatch(vmActions.setVmStatus(VMStatus.Rebooting));
  }
  vmService.setVMStatus(vmId, toggleAction);
  let res = await vmService.getVMById(vmId);

  while (res.data.data.status === oldStatus && getState().virtualMachine.single) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => window.setTimeout(resolve, 1000));
    const vmIdNew = getState().virtualMachine.single?.id;
    if (vmIdNew) {
      res = await vmService.getVMById(vmIdNew);
    }
  }

  if (vmId === getState().virtualMachine.single?.id) {
    dispatch(setSingleVM(new VirtualMachine(res.data.data)));
  }
};

export const fetchDiskSpeed = () => async (dispatch: ThunkVMDispatch) => {
  const { data } = await vmService.fetchDiskSpeed();
  const speed = {
    [DiskType.HDD]: data.data.hddLimit,
    [DiskType.SSD]: data.data.ssdLimit,
  };
  dispatch(setDiskSpeed(speed));
};
