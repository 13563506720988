import { AnyAction } from 'redux';
import { removeDiskReducer, setDiskSizeReducer, setVMStatusReducer } from 'store/virtual-machine/reducer.helpers';
import { ActionTypes, IVMState } from 'store/virtual-machine/types/state.types';

const initialState: IVMState = {
  single: null,
  list: [],
  templates: [],
  osTypes: [],
  paramLimits: {},
  customTemplates: [],
  templateMaxSize: 0,
  diskSpeed: null,
};

const vmReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IVMState = initialState,
  { type, payload }: AnyAction,
): IVMState => {
  switch (type) {
    case ActionTypes.SET_SINGLE_VM:
      return { ...state, single: payload };
    case ActionTypes.CLEAR_SINGLE_VM:
      return { ...state, single: null };
    case ActionTypes.SET_LIST:
      return { ...state, list: payload };
    case ActionTypes.SET_STATUS:
      return setVMStatusReducer(state, payload);
    case ActionTypes.SET_PARAM_LIMITS:
      return { ...state, paramLimits: payload };
    case ActionTypes.SET_TEMPLATES:
      return { ...state, templates: payload };
    case ActionTypes.SET_CUSTOM_TEMPLATES:
      return { ...state, customTemplates: payload };
    case ActionTypes.SET_TEMPLATE_MAX_SIZE:
      return { ...state, templateMaxSize: payload };
    case ActionTypes.SET_OS_TYPES:
      return { ...state, osTypes: payload };
    case ActionTypes.SET_DISK_SIZE:
      return setDiskSizeReducer(state, payload);
    case ActionTypes.DISK_DELETE:
      return removeDiskReducer(state, payload);
    case ActionTypes.SET_DISK_SPEED:
      return { ...state, diskSpeed: payload };
    default:
      return state;
  }
};

export default vmReducer;
