/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import React from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { IAppNotification } from 'store/app-notifications/types';
import './AppNotificationModal.style.scss';

interface IAppNotificationModalProps extends ModalProps {
  notification: IAppNotification | null
  closable?: boolean
}

const AppNotificationModal: React.FC<IAppNotificationModalProps> = ({
  notification, closable, onRead, ...props
}) => {
  const [t] = useTranslation();
  const [editorState, setEditorState] = React.useState<EditorState | undefined>(undefined);

  React.useEffect(() => {
    if (notification?.notificationText) {
      const contentBlock = htmlToDraft(notification.notificationText.replace(/\\n/g, '<br>'));
      if (contentBlock) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlock.contentBlocks),
          ),
        );
      }
    }
  }, [notification]);
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          {notification?.subjectText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Editor
          wrapperClassName="app-notification-modal__wrapper"
          editorState={editorState}
          toolbarHidden
          readOnly
          editorClassName="app-notification-modal__text"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t('common.close')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppNotificationModal;
