import { AnyAction } from 'redux';
import { ActionTypes, Notification } from 'store/notification/types';

const notificationReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Array<Notification> = [],
  action: AnyAction,
) => {
  switch (action.type) {
    case ActionTypes.ADD:
      return [...state, new Notification(action.payload)];
    case ActionTypes.REMOVE:
      return state.filter((notification) => notification.id !== action.payload);
    default:
      return state;
  }
};

export default notificationReducer;
