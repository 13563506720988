import { user } from 'store/user';
import { virtualMachine } from 'store/virtual-machine';
import { notification } from 'store/notification';
import {
  combineReducers, createStore, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { apiErrors } from 'store/api-error';
import { appNotification } from 'store/app-notifications';

export * as UserStore from 'store/user/types';

export const rootReducer = combineReducers({
  user,
  notification,
  virtualMachine,
  apiErrors,
  appNotification,
});

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
