import React from 'react';
import logo from 'assets/images/logo-2-color.svg';
import './EmailConfirmPage.style.scss';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import useAsyncFetch from 'hooks/async-fetch';
import userService from 'services/api/customer/user-service';

const EmailConfirmPage = () => {
  const [t] = useTranslation();
  const params = useParams();
  const [resultMessage, setResultMessage] = React.useState<string>(t('user.confirmed'));
  const [, loading] = useAsyncFetch({
    async asyncFunc() {
      if (params.token) {
        await userService.confirmUserEmail(params.token);
      }
    },
    immediate: true,
    errorHandler(_, errMessage) {
      setResultMessage(errMessage);
    },
  });
  return (
    <main className="not-found-page">
      <div className="not-found-page__logo">
        <img src={logo} width={200} height={80} alt="pro-data" />
      </div>
      {
        !loading && (
          <h1 className="font-heading mb-0">{resultMessage}</h1>
        )
      }
      <Link className="btn btn-outline-secondary" to="/">{t('common.back_to_main')}</Link>
    </main>
  );
};

export default EmailConfirmPage;
