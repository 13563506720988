/* eslint-disable react/jsx-no-useless-fragment */
import NotFound from 'components/Error404';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { UserRole, UserStatus } from 'store/user/types/state.types';

export interface IRequireAuthProps {
  children: React.ReactNode
  roles: UserRole[]
}

const RequireAuth = ({ children, roles }: IRequireAuthProps) => {
  const user = useSelector((state: RootState) => state.user);

  const location = useLocation();
  if (roles.length === 0) {
    return <>{children}</>;
  }

  if (user.role === UserRole.Admin && location.pathname === '/' && user.status !== UserStatus.New) {
    return <Navigate to="/admin" replace />;
  }

  if (user.status === UserStatus.New) {
    return <Navigate to="/" />;
  }

  if (roles.includes(user.role) || roles.length === 0) {
    return <>{children}</>;
  }

  return <NotFound />;
};

export default RequireAuth;
