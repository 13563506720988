import React from 'react';
import LoginForm from 'components/Auth/LoginForm';
import './style.scss';
import logoSrc from 'assets/images/logo-2-white.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Footer from 'components/Footer';
import { Helmet } from 'react-helmet-async';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import ResetPasswordForm from 'components/Auth/ResetPasswordForm/ResetPasswordForm';
import OtpFrom from 'components/Auth/OtpForm';

const AuthPage = () => {
  const [t] = useTranslation();
  const user = useSelector((state: RootState) => state.user);

  return (
    <main className="auth-page">
      <Helmet>
        {`${t('auth.self')} | ${process.env.REACT_APP_PROJECT_TITLE}`}
      </Helmet>
      <aside className="logo-side">
        <div className="logo-side__logo">
          <img src={logoSrc} alt="PRO-DATA" />
        </div>
        <Footer />
      </aside>
      <aside />
      <div className="auth-side">
        <div>
          <LanguageSelector className="auth-lang" />
          <h2 className="font-heading text-primary text-start mb-4 pb-2">{t('title.panel')}</h2>
          {
            user.state === 'expired' && (
              <>
                <h3 className="font-sub-heading mb-4">{t('auth.set_new_password')}</h3>
                <ResetPasswordForm />
              </>
            )
          }
          {
            user.state === 'login' && (
              <LoginForm />
            )
          }
          {
            user.state === '2fa' && (
              <OtpFrom />
            )
          }
        </div>
      </div>
    </main>
  );
};

export default AuthPage;
