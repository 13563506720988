/* eslint-disable consistent-return */
/* eslint-disable import/export */
import axios, { AxiosError } from 'axios';
import useNotification from 'hooks/notification';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IErrorResponse } from 'services/api/axios';

export default function useAsyncRedux<T, K = void>(
  props: {
    asyncFunc: (params: T) => Promise<K>,
    successHandler?: (body?: K) => void,
    errorHandler?: (error: AxiosError<IErrorResponse>) => void,
    errorNote?: boolean,
    successNote?: {
      title: string,
      message: string,
    },
  },
): [(params: T) => Promise<K>, boolean];

export default function useAsyncRedux<T, K = void>(
  props: {
    asyncFunc: (params: T) => Promise<K>,
    immediate: boolean,
    funcParams: T,
    successHandler?: (body?: K) => void,
    errorHandler?: (error: AxiosError<IErrorResponse>) => void, errorNote?: boolean,
    successNote?: {
      title: string,
      message: string,
    },
  },
): [(params: T) => Promise<K>, boolean];

export default function useAsyncRedux<K = void>(
  props: {
    asyncFunc: () => Promise<K>,
    immediate?: boolean,
    successHandler?: (body?: K) => void,
    errorHandler?: (error: AxiosError<IErrorResponse>) => void, errorNote?: boolean,
    successNote?: {
      title: string,
      message: string,
    },
  },
): [() => Promise<K>, boolean];

export default function useAsyncRedux(props: any) {
  const {
    asyncFunc, immediate, funcParams, successHandler, errorHandler, successNote, errorNote,
  } = props;
  const [loading, setLoading] = React.useState<boolean>(!!immediate);
  const mountedRef = React.useRef(true);
  const notification = useNotification();
  const navigate = useNavigate();

  const execute = React.useCallback((params) => {
    setLoading(true);

    return asyncFunc(params)
      .then((res: any) => {
        if (!mountedRef.current) return;

        if (successNote) {
          notification.success(successNote.title, successNote.message);
        }

        if (successHandler) {
          successHandler(res);
        }
        return res;
      })
      .catch((err: any) => {
        if (!mountedRef.current) return;
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            navigate('/');
          }

          if (errorNote) {
            notification.error(err.response?.data.error, err.response?.data.errMessage);
          }

          if (errorHandler) {
            errorHandler(err);
          }
        } else {
          throw new Error(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [asyncFunc, funcParams]);

  React.useEffect(() => {
    if (immediate) {
      execute(funcParams);
    }
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return [
    execute,
    loading,
  ];
}
