/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppNotificationDispatch, useAppSelector } from 'hooks';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/attention.svg';
import classNames from 'classnames';
import { appNotificationActions } from 'store/app-notifications';
import './AppNotificationContainer.style.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAsyncFetch from 'hooks/async-fetch';
import dayjs from 'dayjs';
import { IAppNotification } from 'store/app-notifications/types';
import AppNotificationModal from 'components/AppNotifications/AppNotificationModal/AppNotificationModal';

type CustomToggleProps = {
  count: number
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
};

const CustomToggle = React.forwardRef((
  props: CustomToggleProps,
  ref: React.Ref<HTMLButtonElement>,
) => (
  <Button
    className="app-notification-container__bell"
    ref={ref}
    type="button"
    onClick={(e) => {
      e.preventDefault();
      props.onClick(e);
    }}
  >
    {
      props.count > 0 && (
        <div className="app-notification-container__count">{props.count}</div>
      )
    }
    <BellIcon />
  </Button>
));

type CustomMenuProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  labeledBy?: string;
};

const CustomMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => (
    <div
      ref={ref}
      style={props.style}
      className={`${props.className}`}
      aria-labelledby={props.labeledBy}
    >
      <ul className="list-unstyled">
        {props.children}
      </ul>
    </div>
  ),
);

const AppNotificationContainer = () => {
  const [t] = useTranslation();
  const notifications = useAppSelector((state) => state.appNotification);
  const thunkNotificationDispatch = useAppNotificationDispatch();
  const timer = React.useRef<number | null>(null);
  const [showNotification, setShowNotification] = React.useState<boolean>(false);
  const [notification, setNotification] = React.useState<IAppNotification | null>(null);

  React.useEffect(() => {
    thunkNotificationDispatch(appNotificationActions.fetchNotifications());
    timer.current = window.setInterval(() => {
      thunkNotificationDispatch(appNotificationActions.fetchNotifications());
    }, 30000);
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  const unreadMessages = React.useMemo(() => notifications.notifications
    .filter((message) => !message.wasRead)
    .map((message) => message.id), [notifications]);

  const [handleToggle] = useAsyncFetch({
    async asyncFunc(show: boolean) {
      if (show) {
        thunkNotificationDispatch(appNotificationActions.setRead(unreadMessages));
      } else {
        thunkNotificationDispatch(appNotificationActions.fetchNotifications());
      }
    },
  });

  const showOnLoginMessages = React.useMemo(
    () => notifications.notifications
      .filter((message) => message.showOnPanelLogin && !message.wasRead),
    [notifications.notifications],
  );

  const [handleRead, readLoading] = useAsyncFetch({
    async asyncFunc(id?: number) {
      if (id) {
        await thunkNotificationDispatch(appNotificationActions.setRead([id]));
        await thunkNotificationDispatch(appNotificationActions.fetchNotifications());
      }
    },
  });

  const shownNotifications = React.useMemo(() => notifications.notifications
    .slice(0, 3), [notifications.notifications]);

  return (
    <>
      <AppNotificationModal
        key="usual"
        show={showNotification}
        notification={notification}
        centered
        size="lg"
        scrollable
        onHide={() => setShowNotification(false)}
        onExited={() => setNotification(null)}
      />
      <AppNotificationModal
        key="important"
        show={showOnLoginMessages.length > 0 && !readLoading}
        centered
        size="lg"
        onHide={() => handleRead(showOnLoginMessages[0]?.id)}
        backdrop="static"
        scrollable
        notification={showOnLoginMessages[0]}
        closeable
      />
      <Dropdown className={classNames('app-notification-container')} onToggle={handleToggle}>
        <Dropdown.Toggle
          count={notifications.unreadCount}
          as={CustomToggle}
        />
        <Dropdown.Menu renderOnMount align="start" as={CustomMenu}>
          {shownNotifications.length === 0 && (
            <Dropdown.ItemText className="text-center p-4 font-body-m">{t('app_notification.empty')}</Dropdown.ItemText>
          )}
          {shownNotifications.map((notify) => (
            <Dropdown.Item
              key={notify.id}
              as="li"
              className={classNames('app-notification-container__item', {
                'app-notification-container__unread': !notify.wasRead,
              })}
            >
              <button type="button" className="btn-styleless" onClick={() => { setNotification(notify); setShowNotification(true); }}>
                <div>
                  <div>
                    {
                      notify.showOnPanelLogin && (
                        <WarningIcon className="text-warning me-2" />
                      )
                    }
                    <span className="text-truncate">{notify.subjectText}</span>
                  </div>
                </div>
                <div className="text-truncate font-body-s mb-1">{notify.simpleText}</div>
                <div className="text-end opacity-50">{dayjs(notify.dispatchDate).format('DD-MM-YYYY HH:mm')}</div>
              </button>
            </Dropdown.Item>
          ))}
          {
            !!shownNotifications.length && (
            <Dropdown.Item as="li" className="app-notification-container__item app-notification-container__show-all">
              <Link className="" to="/cabinet/notifications">{t('app_notification.show_all')}</Link>
            </Dropdown.Item>
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default AppNotificationContainer;
