/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import { ReactComponent as ArrowDown } from 'assets/icons/arrows/chevron-down.svg'; import './style.scss';
import { useTranslation } from 'react-i18next';

type CustomToggleProps = {
  size?: 'sm' | 'lg';
  disabled?: boolean;
  children: React.ReactNode;
  isInvalid?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
};

const CustomToggle = React.forwardRef((
  props: CustomToggleProps,
  ref: React.Ref<HTMLButtonElement>,
) => (
  <button
    className={`form-control text-truncate ${props.size === 'lg' ? 'form-control-lg' : ''} ${props.isInvalid ? 'is-invalid' : ''}`}
    type="button"
    ref={ref}
    disabled={props.disabled}
    onClick={(e) => {
      e.preventDefault();
      props.onClick(e);
    }}
  >
    <span>{props.children}</span>
    <ArrowDown className="arrow" />
  </button>
));

type CustomMenuProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  labeledBy?: string;
  size?: 'sm' | 'lg';
};

const CustomMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => (
    <div
      ref={ref}
      style={props.style}
      className={`${props.className} ${props.size === 'lg' ? 'custom-menu-lg' : ''}`}
      aria-labelledby={props.labeledBy}
    >
      <ul className="list-unstyled">
        {props.children}
      </ul>
    </div>
  ),
);

export interface IInputDropdownProps extends Omit<DropdownProps, 'children'> {
  value: string | null;
  size?: 'sm' | 'lg';
  data: Array<{
    value: string;
    label: string | JSX.Element;
    disabled?: boolean;
    [key: string]: any;
  }>
  children?: React.ReactNode;
  disabled?: boolean
  error?:string
  isInvalid?: boolean
  placeholder?: string
}

const InputDropdown: React.FC<IInputDropdownProps> = ({
  value, data, className, size, disabled, isInvalid, error, placeholder, ...dropdownProps
}) => {
  const [t] = useTranslation();

  return (
    <>
      <Dropdown {...dropdownProps} className={`input-dropdown ${className || ''}`} id="input-dropdown">
        <Dropdown.Toggle
          size={size}
          as={CustomToggle}
          disabled={disabled || data.length === 0}
          isInvalid={isInvalid}
        >
          {data.find((_data) => _data.value === value)?.label || placeholder || t('common.select')}
        </Dropdown.Toggle>
        <Dropdown.Menu size={size} as={CustomMenu}>
          {data.map((item) => (
            <Dropdown.Item
              key={item.value}
              eventKey={item.value}
              disabled={item.disabled}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <div className="invalid-feedback" style={{ display: isInvalid ? 'block' : 'none' }}>{error}</div>
    </>
  );
};

export default InputDropdown;
