import { IResponse } from 'services/api/axios';

export enum ActionTypes {
  SET_NOTIFICATIONS = 'app-notification:set-notifications',
  SET_UNREAD_COUNT = 'app-notification:set-unread-count',
  SET_READ = 'app-notification:set-read',
}

export interface IAppNotification {
  id: number,
  subjectText: string,
  notificationText: string
  simpleText?: string
  dispatchDate: string
  wasRead: boolean
  showOnPanelLogin: boolean
  important: boolean
  showToAdmin: boolean
  sendByTelegram: boolean
  sendByEmail: boolean
  showInPanel: boolean
}

export interface IAppNotificationState {
  notifications: IAppNotification[]
  unreadCount: number
}

export interface IAppNotificationsRes extends IResponse {
  data: [
    {
      cntWasRead: 1
      list: Array<IAppNotification>,
    },
  ]
}

export interface ITelegramAttachDto {
  code: number
}
