import React from 'react';
import {
  Navigate,
  Outlet,
  createBrowserRouter,
} from 'react-router-dom';
// import Layout from 'components/Layout';
import NotFound from 'components/Error404';

import AuthPage from 'pages/Auth/auth';
import App from 'App';
import customerRoutes from 'routes/routes.customer';
import EmailConfirmPage from 'pages/customer/settings/users/EmailConfirmPage/EmailConfirmPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        path: '',
        element: <AuthPage />,
      },
      {
        path: 'auth',
        element: <Navigate to="/" replace />,
      },
      {
        path: 'notification-email',
        element: <Outlet />,
        children: [
          {
            path: ':token',
            element: <EmailConfirmPage />,
          },
        ],
      },
      customerRoutes,

    ],
  },
]);

export default router;
