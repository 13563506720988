import VirtualMachine from 'store/virtual-machine/types/virtual-machine';
import { ReactComponent as CentOs } from 'assets/icons/os/cent-os.svg';
import { ReactComponent as Debian } from 'assets/icons/os/debian.svg';
import { ReactComponent as Fedora } from 'assets/icons/os/fedora.svg';
import { ReactComponent as Oracle } from 'assets/icons/os/oracle.svg';
import { ReactComponent as Rocky } from 'assets/icons/os/rocky.svg';
import { ReactComponent as Ubuntu } from 'assets/icons/os/ubuntu.svg';
import { ReactComponent as Windows } from 'assets/icons/os/windows.svg';
import { ReactComponent as All } from 'assets/icons/os/all.svg';
import { ReactComponent as Alma } from 'assets/icons/os/alma.svg';
import React from 'react';
import { IDiskSpeedDto, IOSTypeDto } from 'store/virtual-machine/types/api-bodies.types';
import { IOSTemplateDto } from 'components/OSTemplates/types/dtos';

export enum ActionTypes {
  SET_SINGLE_VM = 'vm: set-single',
  CLEAR_SINGLE_VM = 'vm: clear-single',
  SET_STATUS = 'vm: set-status',
  SET_LIST = 'vm: set-list',
  SET_REGIONS = 'vm: set-regions',
  SET_TEMPLATES = 'vm: set-templates',
  SET_CUSTOM_TEMPLATES = 'vm: set-custom-templates',
  SET_PARAM_LIMITS = 'vm: set-param-limits',
  SET_DISK_SIZE = 'vm: set-disk-size',
  DISK_DELETE = 'vm: set-disk-delete',
  AS_BOOT_DISK = 'vm: as-boot-disk',
  EDIT_NETWORK = 'vm edit-network',
  SET_OS_TYPES = 'vm: set-os-types',
  SET_TEMPLATE_MAX_SIZE = 'vm: set-template-max-size',
  SET_DISK_SPEED = 'vm: set-disk-speed',
}

export enum OSTypeId {
  'init',
  'ubuntu',
  'windows',
  'cent',
  'oracle',
  'debian',
  'rocky',
  'alma',
  'fedora',
  'custom',
}

type OSType = {
  [key in OSTypeId]: {
    name: string,
    Icon: JSX.Element
  }
};

export const OS_TYPES: OSType = {
  [OSTypeId.init]: {
    name: 'All',
    Icon: <All />,
  },
  [OSTypeId.custom]: {
    name: 'EmptyOs',
    Icon: <All />,
  },
  [OSTypeId.debian]: {
    name: 'Debian',
    Icon: <Debian />,
  },
  [OSTypeId.windows]: {
    name: 'Windows',
    Icon: <Windows />,
  },
  [OSTypeId.cent]: {
    name: 'Cent OS',
    Icon: <CentOs />,
  },
  [OSTypeId.fedora]: {
    name: 'Fedora',
    Icon: <Fedora />,
  },
  [OSTypeId.oracle]: {
    name: 'Oracle Linux',
    Icon: <Oracle />,
  },
  [OSTypeId.rocky]: {
    name: 'Rocky Linux',
    Icon: <Rocky />,
  },
  [OSTypeId.ubuntu]: {
    name: 'Ubuntu',
    Icon: <Ubuntu />,
  },
  [OSTypeId.alma]: {
    name: 'Alma Linux',
    Icon: <Alma />,
  },
} as const;

export enum VMStatus {
  New = 'STATUS_NEW',
  Success = 'STATUS_SUCCESS',
  Starting = 'STATUS_STARTING',
  Started = 'STATUS_RUNNING',
  Stopping = 'STATUS_STOPPING',
  Stopped = 'STATUS_STOPPED',
  Deleted = 'STATUS_DELETED',
  Fail = 'STATUS_FAIL',
  ShuttingDown = 'STATUS_PROCESS_SHUTDOWN',
  Rebooting = 'STATUS_REBOOTING',
  OnPause = 'STATUS_PAUSE',
  Hibernated = 'STATUS_HIBERNATE',
  BackupRestore = 'STATUS_BACKUP_RESTORE',
  SnapshotRestore = 'STATUS_SNAPSHOT_ROLLBACK',
}

export const vmStatusDisabledDiskList = [
  VMStatus.Success,
  VMStatus.Starting,
  VMStatus.Started,
  VMStatus.Stopping,
  VMStatus.ShuttingDown,
  VMStatus.Rebooting,
  VMStatus.OnPause,
  VMStatus.Hibernated,
  VMStatus.BackupRestore,
  VMStatus.SnapshotRestore,
];

export enum VMStateAction {
  Shutdown = 'SHUTDOWN',
  Reboot = 'REBOOT',
  Pause = 'PAUSE',
  Hibernate = 'HIBERNATE',
  Stop = 'STOP',
  Reset = 'RESET',
  Start = 'START',
  Resume = 'RESUME',
}

export enum DiskType {
  HDD = 'HDD',
  SSD = 'SSD',
}

export const diskTypeNamesShort = {
  [DiskType.HDD]: 'disk.disk_types.hdd',
  [DiskType.SSD]: 'disk.disk_types.ssd',
};
export const diskTypeNamesLong = {
  [DiskType.HDD]: 'disk.disk_types.hdd_long',
  [DiskType.SSD]: 'disk.disk_types.ssd_long',
};

export interface IVMTemplate {
  vmTemplateId: number;
  osName: string;
  description: string;
  custom: boolean
  sshAvail: boolean
  osTypeId: OSTypeId
  defaultDiskSize: number
}

export enum LimitedVMParam {
  CPUCores = 'cpu_cores',
  RAM = 'ram',
  HDDSize = 'hdd_size',
  SSDSize = 'ssd_size',
  PublicIP = 'public_ip',
  LocalNetworks = 'vlan',
  Backup = 'backup',
  Snapshot = 'snapshot',
  ScheduledBackup = 'scheduled_backup',
  BackupQuota = 'backup_quota',
  CustomTemplates = 'custom_image',
}

export const VM_PARAM_HIDDEN_REPORT = [LimitedVMParam.ScheduledBackup,
  LimitedVMParam.Snapshot, LimitedVMParam.CustomTemplates];

export type IParamLimits = {
  [key in LimitedVMParam]?: {
    max: number
    min: number
    price: number
    maxRatio?: number
    minRatio?: number
    limit: number
  }
};

export type ParamNames = {
  [key in LimitedVMParam]: string

};

export const limitedVMParamNames: ParamNames = {
  [LimitedVMParam.CPUCores]: `report.resources.${LimitedVMParam.CPUCores}`,
  [LimitedVMParam.RAM]: `report.resources.${LimitedVMParam.RAM}`,
  [LimitedVMParam.HDDSize]: `report.resources.${LimitedVMParam.HDDSize}`,
  [LimitedVMParam.SSDSize]: `report.resources.${LimitedVMParam.SSDSize}`,
  [LimitedVMParam.PublicIP]: `report.resources.${LimitedVMParam.PublicIP}`,
  [LimitedVMParam.LocalNetworks]: `report.resources.${LimitedVMParam.LocalNetworks}`,
  [LimitedVMParam.Backup]: `report.resources.${LimitedVMParam.Backup}`,
  [LimitedVMParam.ScheduledBackup]: `report.resources.${LimitedVMParam.ScheduledBackup}`,
  [LimitedVMParam.Snapshot]: `report.resources.${LimitedVMParam.Snapshot}`,
  [LimitedVMParam.BackupQuota]: `report.resources.${LimitedVMParam.Backup}`,
  [LimitedVMParam.CustomTemplates]: `report.resources.${LimitedVMParam.CustomTemplates}`,
};

export const limitedVMParamColors: ParamNames = {
  [LimitedVMParam.CPUCores]: '#32B58E',
  [LimitedVMParam.RAM]: '#BEEDAA',
  [LimitedVMParam.HDDSize]: '#F09253',
  [LimitedVMParam.SSDSize]: '#ED7471',
  [LimitedVMParam.PublicIP]: '#FDF287',
  [LimitedVMParam.LocalNetworks]: '#F092DD',
  [LimitedVMParam.Backup]: '#6B59C2',
  [LimitedVMParam.BackupQuota]: '#6B59C2',
  [LimitedVMParam.ScheduledBackup]: '#4C1A57',
  [LimitedVMParam.Snapshot]: '#006494',
  [LimitedVMParam.CustomTemplates]: '#AD2D2A',
};

export type ResourcesPrice = {
  [key in LimitedVMParam]?: {
    count: number
    price: number
  }
};

export interface IDisk {
  id: number,
  name: string | null,
  size: number
  diskType: DiskType
  boot: boolean
}
export interface IVMState {
  list: Array<VirtualMachine>
  single: VirtualMachine | null
  templates: Array<IVMTemplate>
  customTemplates: Array<IOSTemplateDto>
  templateMaxSize: number
  osTypes: Array<IOSTypeDto>
  paramLimits: IParamLimits
  diskSpeed: {
    [DiskType.HDD]: IDiskSpeedDto
    [DiskType.SSD]: IDiskSpeedDto
  } | null
}
