import { Axios } from 'axios';
import apiClient from 'services/api/axios';
import { IApiErrorDtoRes } from 'store/api-error/types/api-error';
import {
  IAppVersionDto,
  IChangePasswordBody, IGetUserRes, ILoginBody,
  IOtpAuthDto,
  IRegionsResDto, IRegisterBody, IUserLoginResBody, IUserRegisterRes,
} from 'store/user/types/api-bodies.types';

class AuthService {
  api: Axios;

  constructor(api: Axios) {
    this.api = api;
  }

  register(body: IRegisterBody) {
    return this.api.post<IUserRegisterRes>('sign-in/register', body);
  }

  logIn(body: ILoginBody) {
    return this.api.post<IUserLoginResBody>('sign-in/auth', body);
  }

  loginConfirm({ totpKey, ...dto }: IOtpAuthDto) {
    return this.api.post<IUserLoginResBody>('2fa/validateQrCode', { ...dto, totpKey: Number(totpKey) });
  }

  getProfile() {
    return this.api.get<IGetUserRes>('/panel/user');
  }

  getErrorsMap() {
    return this.api.get<IApiErrorDtoRes>('panel/getAllErrorCodes');
  }

  logOut() {
    return this.api.patch('/logout');
  }

  changePassword(body: IChangePasswordBody) {
    return this.api.post<IUserLoginResBody>('/sign-in/change', body);
  }

  getUserRegions() {
    return this.api.get<IRegionsResDto>('/panel/regions');
  }

  setRegion(regionId: string) {
    return this.api.post(`user/setRegion?id=${regionId}`);
  }

  setLanguage(language: string) {
    return this.api.post('panel/setLanguage', undefined, { params: { language } });
  }

  getVersion() {
    return this.api.get<IAppVersionDto>('panel/frontend/currentVersion');
  }
}

export default new AuthService(apiClient);
