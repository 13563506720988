import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Collapse } from 'react-bootstrap';
import styles from './NavLink.module.scss';

export interface INavLinkConfig {
  path: string;
  title: string;
  Icon?: React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
  >;
  children?: INavLinkConfig[];
  counter?: number;
  className?: string;
  onClick?: () => void;
}
interface INavLinkProps {
  className?: string;
  path?: string;
  title: string;
  onClick?: () => void;
  counter?: number;
  subLinks?: INavLinkConfig['children'];
  Icon?: React.ComponentType;
}

const NavLink: React.FC<INavLinkProps> = ({
  className,
  path,
  title,
  Icon,
  counter,
  subLinks,
  onClick,
}) => {
  const location = useLocation();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [isParentActive, setIsParentActive] = React.useState<boolean>(false);

  const activeKey = React.useMemo(
    () => location.pathname.split('/').splice(0, 3).join('/'),
    [location],
  );

  const activeSubKey = React.useMemo(
    () => location.pathname.split('/').splice(0, 4).join('/'),
    [location],
  );

  const handleExpand = () => {
    if (!isParentActive) {
      setIsExpanded((value) => !value);
    }
  };

  React.useEffect(() => {
    if (subLinks?.some((link) => link.path === activeSubKey)) {
      setIsExpanded(true);
      setIsParentActive(true);
    } else {
      setIsExpanded(false);
      setIsParentActive(false);
    }
  }, [location.pathname, activeSubKey]);

  if (Array.isArray(subLinks)) {
    return (
      <div className={classnames(className, styles.wrapper)}>
        <button
          type="button"
          className={classnames(styles.toggle, { [styles.active]: isParentActive })}
          onClick={handleExpand}
        >
          {Icon && <Icon />}
          <span>{title}</span>
        </button>
        <Collapse in={isExpanded}>
          <div className={styles.list}>
            {subLinks.map((link) => (
              <Link
                key={link.path}
                className={classnames(styles.navLink, link.className, {
                  [styles.active]: activeSubKey === link.path,
                })}
                to={link.path || ''}
                onClick={link.onClick}
              >
                {link?.Icon ? <link.Icon /> : <div />}
                <span>{link.title}</span>
                {!!link.counter && link.counter > 0 && <div>{link.counter}</div>}
              </Link>
            ))}
          </div>
        </Collapse>
      </div>
    );
  }

  return (
    <Link
      className={classnames(styles.navLink, className, { [styles.active]: activeKey === path })}
      to={path || ''}
      onClick={onClick}
    >
      {Icon ? <Icon /> : <div />}
      <span>{title}</span>
      {!!counter && counter > 0 && <div>{counter}</div>}
    </Link>
  );
};
export default NavLink;
