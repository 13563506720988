/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import dayjs from 'dayjs';

export function phoneFormatter(phone?: string) {
  const cleaned = (`${phone}`).replace(/\D/g, '');
  const match = cleaned.match(/^(998|)?(\d{2})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    const intlCode = (match[1] ? '+998 ' : '');
    return [intlCode, match[2], ' ', match[3], ' ', match[4], match[5]].join('');
  }
  return phone;
}

export function bankAccountFormatter(account: string | number) {
  // insert space between every 4 digits
  return account.toString().replace(/(\d{4})(?=(\d+))/g, '$& ');
}

export function cardPanFormatter(pan: string | number) {
  // insert space between every 4 digits
  return pan.toString().replace(/(\S{4})(?=(\S+))/g, '$& ');
}

export function mediaLinkFormatter(link: string) {
  return `${process.env.REACT_APP_API_URL}/file/download/${link}`;
}

export function textTruncate(text: string, length: number) {
  if (text.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
}

export function moneyFormatter(money?: number, disableRound?: boolean) {
  if (!money) return 0;
  if (disableRound) {
    return (money / 100).toFixed(2).replace(/(\d)(?=((\d{3})+)(\D|$))/g, '$& ');
  }
  return Math.round(money / 100).toString().replace(/(\d)(?=((\d{3})+)(\D|$))/g, '$& ');
}

// export function numberFormatter(value?: number, fixed?: number, cutZeros?: boolean) {
//   if (!value) return 0;
//   const roundedValue = value.toFixed(2 || 0); // Округляем знаков после запятой
//   const [integerPart, decimalPart] = roundedValue.split('.'); // Разбиваем на целую и десятичную части

//   // Удаляем нулевые десятые и сотые, если они равны нулю
//   const decimalPartTrimmed = (parseInt(decimalPart, 10) === 0 && cutZeros) ? '' : `.${decimalPart}`;

//   // Возвращаем округленное число
//   return `${integerPart.replace(/(\d)(?=((\d{3})+)(\D|$))/g, '$& ')}${decimalPartTrimmed}`;
// }

export function numberFormatter(value?: number, fixed?: number) {
  if (!value) return 0;
  return value.toFixed(fixed || 0).toString().replace(/(\d)(?=((\d{3})+)(\D|$))/g, '$& ');
}

require('dayjs/locale/ru');

export function dateFormatter(date: number | Date | undefined | null, format: string) {
  return dayjs(date).locale('ru').format(format);
}

// https://snipp.ru/jquery/word-declination-js
export function numbersDeclension(value: number, words: [string, string, string]) {
  const valueEnd = Math.abs(value) % 100;
  const num = valueEnd % 10;
  if (valueEnd > 10 && valueEnd < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
}

export const downloadSshKeyFile = (vmName: string, content: string, isPrivate: boolean) => () => {
  const link = document.createElement('a');
  link.download = `${vmName}_rsa${isPrivate ? '.ppk' : '.pub'}`;
  const blob = new Blob([content], { type: 'text/plain' });
  link.href = window.URL.createObjectURL(blob);
  link.click();
};

/**
 *
 * @param ipRange accept IP range and comma separated list
 */
export const checkIPRange = (ipRange?: string | null): boolean => {
  if (!ipRange) return true;
  const ipArr = ipRange.split(',');
  // split ranges
  const ipAddressArr = ipArr.reduce<string[]>((res, ip) => {
    res.push(...ip.split('-'));
    return res;
  }, [] as string[]);

  const ipNumbersArr = ipAddressArr.reduce<number[]>((res, ip) => {
    res.push(...ip.split('.').map((stringIp) => Number(stringIp)));
    return res;
  }, []);

  for (let index = 0; index < ipNumbersArr.length; index++) {
    const element = ipNumbersArr[index];
    if (element > 255) {
      return false;
    }
  }
  return true;
};

/**
 *
 * @param portRange accept IP range and comma separated list
 */
export const checkPortRange = (portRange?: string | null): boolean => {
  if (!portRange) return true;
  const portArr = portRange.split(',');
  // split ranges
  const portAddressArr = portArr.reduce<string[]>((res, port) => {
    res.push(...port.split(':'));
    return res;
  }, [] as string[]);

  const portNumbersArr = portAddressArr.reduce<number[]>((res, port) => {
    const portNumber = Number(port);
    if (portNumber) {
      res.push(portNumber);
    }
    return res;
  }, []);

  for (let index = 0; index < portNumbersArr.length; index++) {
    const element = portNumbersArr[index];
    if (element > 65535) {
      return false;
    }
  }
  return true;
};
