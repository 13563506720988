import React, { Suspense } from 'react';

import Layout from 'components/Layouts/AppLayout';

import { UserRole } from 'store/user/types/state.types';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import RequireAuth from 'components/Auth/RequireAuth';
import PageLoader from 'components/Loaders/PageLoader';

const VMTablePage = React.lazy(() => (import ('pages/customer/VM/vm.table')));
const VMCreatePage = React.lazy(() => (import ('pages/customer/VM/vm.create')));
const VMInfoPage = React.lazy(() => (import ('pages/customer/VM/vm.info')));
const VMEditPage = React.lazy(() => (import ('pages/customer/VM/vm.edit')));

const NetworksPage = React.lazy(() => (import ('pages/customer/networks/Table.networks')));
// const NetworkCreatePage = React.lazy(() => (import ('pages/customer/networks/Create.networks')));

const DisksPage = React.lazy(() => (import ('pages/customer/disks/Table.disks')));

const BackupsPage = React.lazy(() => (import ('pages/customer/backups/Table.backups')));
const OSTemplatesPage = React.lazy(() => (import ('pages/customer/templates/templates.table')));

const ReportsPage = React.lazy(() => (import ('pages/customer/report/report')));
const SupportPage = React.lazy(() => (import ('pages/customer/support/support')));

const LogPage = React.lazy(() => (import ('pages/customer/settings/log/log')));
const ChangePasswordPage = React.lazy(() => (import ('pages/customer/settings/change-pass/ChangePassPage')));
const TwoFAPage = React.lazy(() => (import ('pages/customer/settings/two-fa/TwoFA')));

const UsersPage = React.lazy(() => (import ('pages/customer/settings/users/Users.table')));
const UserCreatePage = React.lazy(() => (import ('pages/customer/settings/users/Users.create')));
const UserEditPage = React.lazy(() => (import ('pages/customer/settings/users/Users.edit')));

const AppNotificationsTable = React.lazy(() => (import ('pages/customer/settings/notifications/AppNotificationsTable')));
const AppNotificationPage = React.lazy(() => (import ('pages/customer/settings/notifications/AppNotification')));
const AppNotificationSettings = React.lazy(() => (import ('pages/customer/settings/notifications/AppNotificationsSettings')));

const FirewallPage = React.lazy(() => (import ('pages/customer/settings/firewall/Firewall')));
const FirewallRulesGroupPage = React.lazy(() => (import ('pages/customer/settings/firewall/GroupRules')));

const customerRoutes: RouteObject = {
  path: 'cabinet',
  element: (
    <RequireAuth roles={[UserRole.Customer]}>
      <Layout />
    </RequireAuth>
  ),
  children: [
    {
      path: '',
      element: <Navigate to="servers" replace />,
    },
    {
      path: 'servers',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><VMTablePage /></Suspense>,
        },
        {
          path: 'create',
          element: <Suspense fallback={<PageLoader />}><VMCreatePage /></Suspense>,
        },
        {
          path: ':id',
          element: <Suspense fallback={<PageLoader />}><VMInfoPage /></Suspense>,
        },
        {
          path: ':id/edit',
          element: <Suspense fallback={<PageLoader />}><VMEditPage /></Suspense>,
        },
      ],
    },
    {
      path: 'networks',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><NetworksPage /></Suspense>,
        },
      ],
    },
    {
      path: 'firewall',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><FirewallPage /></Suspense>,
        },
        {
          path: 'group/:groupId',
          element: <Suspense fallback={<PageLoader />}><FirewallRulesGroupPage /></Suspense>,
        },
      ],
    },
    {
      path: 'disks',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><DisksPage /></Suspense>,
        },
      ],
    },
    {
      path: 'backups',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><BackupsPage /></Suspense>,
        },
      ],
    },
    {
      path: 'templates',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><OSTemplatesPage /></Suspense>,
        },
      ],
    },
    {
      path: 'reports',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><ReportsPage /></Suspense>,
        },
      ],
    },
    {
      path: 'support',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><SupportPage /></Suspense>,
        },
      ],
    },
    {
      path: 'settings',
      element: <Outlet />,
      children: [
        {
          path: 'log',
          element: <Suspense fallback={<PageLoader />}><LogPage /></Suspense>,
        },
        {
          path: 'change-pass',
          element: <Suspense fallback={<PageLoader />}><ChangePasswordPage /></Suspense>,
        },
        {
          path: 'two-fa',
          element: <Suspense fallback={<PageLoader />}><TwoFAPage /></Suspense>,
        },

        {
          path: 'users',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <Suspense fallback={<PageLoader />}><UsersPage /></Suspense>,
            },
            {
              path: 'add',
              element: <Suspense fallback={<PageLoader />}><UserCreatePage /></Suspense>,
            },
            {
              path: ':userId',
              element: <Suspense fallback={<PageLoader />}><UserEditPage /></Suspense>,
            },
          ],
        },
      ],
    },
    {
      path: 'profile',
      element: <Outlet />,
      children: [
        {
          path: 'edit',
          element: <Suspense fallback={<PageLoader />}><UserEditPage /></Suspense>,
        },
      ],
    },
    {
      path: 'notifications',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Suspense fallback={<PageLoader />}><AppNotificationsTable /></Suspense>,
        },
        {
          path: 'settings',
          element: <Suspense fallback={<PageLoader />}><AppNotificationSettings /></Suspense>,
        },
        {
          path: ':notificationId',
          element: <Suspense fallback={<PageLoader />}><AppNotificationPage /></Suspense>,
        },
      ],
    },
  ],
};

export default customerRoutes;
