import { Axios } from 'axios';
import {
  IOSTemplateListDto,
  IOSTemplateUPloadInitReqDto,
  IOSTemplateUPloadInitResDto,
  IOSTemplateUploadPartDto,
  ITemplateMaxSizeDto,
} from 'components/OSTemplates/types/dtos';
import apiClient from 'services/api/axios';

class OSTemplateService {
  api: Axios;

  constructor(api: Axios) {
    this.api = api;
  }

  getTemplates() {
    return this.api.get<IOSTemplateListDto>('os_templates/list');
  }

  deleteTemplate(id: number, cancel: boolean) {
    return this.api.post(`os_templates/${id}/delete`, undefined, { params: { cancel } });
  }

  uploadInit(dto: IOSTemplateUPloadInitReqDto) {
    return this.api.post<IOSTemplateUPloadInitResDto>('os_templates/init_os', dto);
  }

  uploadPart(dto: IOSTemplateUploadPartDto) {
    return this.api.post('os_templates/upload_file_part', dto);
  }

  getTemplateMaxSize() {
    return this.api.get<ITemplateMaxSizeDto>('panel/getAppSettings');
  }
}

export default new OSTemplateService(apiClient);
