/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactEventHandler } from 'react';
import './style.scss';
import {
  Nav,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  useLocation,
} from 'react-router-dom';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';

import { ReactComponent as VMIcon } from 'assets/icons/server.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/question.svg';
import { ReactComponent as NetworkIcon } from 'assets/icons/network.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/growth.svg';
import { ReactComponent as DiscIcon } from 'assets/icons/disc.svg';
import { ReactComponent as HDDIcon } from 'assets/icons/hdd.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as BackupIcon } from 'assets/icons/backup.svg';
import { ReactComponent as LogIcon } from 'assets/icons/log.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password.svg';
import { ReactComponent as BackIcon } from 'assets/icons/arrows/chevron-left.svg';
import { ReactComponent as TwoFAIcon } from 'assets/icons/two-fa.svg';
import { ReactComponent as ShieldIcon } from 'assets/icons/shield.svg';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';

import { UserRole } from 'store/user/types/state.types';
import NavLink, { INavLinkConfig } from 'components/SideMenu/NavLink/NavLink';

const SUBMENU_PATHS = [
  '/cabinet/settings',
  '/cabinet/profile',
  '/cabinet/notifications',
];
export interface ISideMenuProps {
  handleMenuToggle: ReactEventHandler
}

const SideMenu = () => {
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const { user } = useSelector((state: RootState) => state);

  const activeKey = React.useMemo(() => {
    if (location.pathname.startsWith('/cabinet/settings')) {
      return location.pathname.split('/').splice(0, 4).join('/');
    }
    return location.pathname.split('/').splice(0, 3).join('/');
  }, [user, location]);

  const CustomerLinks = React.useMemo<INavLinkConfig[]>(() => {
    if (SUBMENU_PATHS.some((path) => location.pathname.startsWith(path))) {
      const settingsLinks: INavLinkConfig[] = [
        {
          path: '/cabinet/profile',
          title: t('profile.self'),
          // Icon: UserIcon,
          children: [
            {
              path: '/cabinet/profile/edit',
              title: t('user.user_settings'),
              Icon: SettingsIcon,
            },
          ],
        },
        {
          path: '/cabinet/settings',
          title: t('security.self'),
          // Icon: ShieldPersonIcon,
          children: [
            {
              path: '/cabinet/settings/log',
              title: t('log.self'),
              Icon: LogIcon,
            },
            {
              path: '/cabinet/settings/change-pass',
              title: t('auth.change_pass'),
              Icon: PasswordIcon,
            },
            {
              path: '/cabinet/settings/two-fa',
              title: t('two_fa.self'),
              Icon: TwoFAIcon,
            },
          ],
        },
        {
          path: '/cabinet/notifications',
          title: t('app_notification.self_plural'),
          children: [
            {
              path: '/cabinet/notifications/settings',
              title: t('app_notification.settings'),
              Icon: SettingsIcon,
            },
            {
              path: '/cabinet/notifications',
              title: t('app_notification.list'),
              Icon: BellIcon,
            },
          ],
        },
        {
          path: '/cabinet/servers',
          title: t('common.back'),
          Icon: BackIcon,
        },
      ];
      if (user.parentUserId === user.id) {
        settingsLinks[0].children?.splice(
          1,
          0,
          {
            path: '/cabinet/settings/users',
            title: t('user.users_manage'),
            Icon: UserIcon,
          },
        );
      }
      return settingsLinks;
    }
    return [
      {
        path: '/cabinet/servers',
        title: t('vm.self_plural'),
        Icon: VMIcon,
      },
      {
        path: '/cabinet/networks',
        title: t('network.self_plural'),
        Icon: NetworkIcon,
      },
      {
        path: '/cabinet/firewall',
        title: t('firewall.title'),
        Icon: ShieldIcon,
      },
      {
        path: '/cabinet/disks',
        title: t('disk.self_plural'),
        Icon: HDDIcon,
      },
      {
        path: '/cabinet/backups',
        title: t('backup.self_plural'),
        Icon: BackupIcon,
      },
      {
        path: '/cabinet/templates',
        title: t('os_template.title'),
        Icon: DiscIcon,
      },
      {
        path: '/cabinet/reports',
        title: t('report.self_short'),
        Icon: ReportIcon,
      },
    ];
  }, [i18n.language, location, user]);

  const handleScroll = () => {
    const header = document.querySelector('header.app-header');
    const menuContainer = document.querySelector('aside.side-menu');
    const screenHeight = document.documentElement.clientHeight;
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );

    if (menuContainer && scrollHeight > screenHeight) {
      const offset = header?.getBoundingClientRect().top || 0;
      const container = menuContainer as HTMLElement;

      container.style.height = `calc(100vh - (16px * 2) - 70px - ${offset < -70 ? -70 : offset}px)`;
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <aside className="side-menu">
      <div className="side-menu__container">
        <Nav as="nav" className="main-app-nav" activeKey={activeKey} defaultActiveKey="/">
          {CustomerLinks.map((link) => (
            <NavLink
              key={link.path}
              className={link.className}
              path={link.path}
              title={link.title}
              onClick={link.onClick}
              counter={link.counter}
              subLinks={link.children}
              Icon={link.Icon}
            />
          ))}
          <a className="main-nav-link nav-link mt-auto main-nav-link__lg" target="_blank" href="https://pro-data.tech/frequently-asked-questions/#b9909" rel="noreferrer">
            <SupportIcon />
            <span>
              {t('contacts.faq')}
            </span>
          </a>
          <a className="main-nav-link nav-link main-nav-link__lg" target="_blank" href="https://t.me/PRO_DATA_Support_Bot" rel="noreferrer">
            <TelegramIcon />
            <span>
              {t('contacts.bot')}
              {' '}
              <span className="font-body-s">@PRO_DATA_Support_Bot</span>
            </span>
          </a>
        </Nav>
      </div>
    </aside>
  );
};

export default SideMenu;
