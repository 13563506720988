import React from 'react';
import logo from 'assets/images/logo-2-color.svg';
import './Error404.style.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const [t] = useTranslation();
  return (
    <main className="not-found-page">
      <div className="not-found-page__logo">
        <img src={logo} width={200} height={80} alt="pro-data" />
      </div>
      <h1 className="font-heading mb-0">404</h1>
      <div className="font-body-m">{t('common.page_not_found')}</div>
      <Link className="btn btn-outline-secondary" to="/">{t('common.back_to_main')}</Link>
    </main>
  );
};

export default NotFound;
