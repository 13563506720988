export function updateObjectState<T>(oldState: T, newState: Partial<T>) {
  return { ...oldState, ...newState };
}

export function updateClassState<T>(oldState: T, newState: T):T {
  return Object.assign(
    Object.create(Object.getPrototypeOf(oldState)),
    { ...newState },
  );
}
