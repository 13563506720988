import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { numberFormatter, numbersDeclension } from 'utils/formatters';
import './style.scss';

const ResourcesLimit: React.FC = () => {
  const [t] = useTranslation();
  const limits = useSelector((state:RootState) => state.virtualMachine.paramLimits);

  return (
    <section className="resources-limits bg-white d-flex me-3">
      <div className="font-bold-body-s">{`${t('vm.accessible_resources')}:`}</div>
      <div className="resources-limits__container">
        <div className="font-thin-body-s">
          <span className="text-color-light">{`${t('vm.params.cpu')}: `}</span>
          <strong>
            {`${numberFormatter(limits.cpu_cores?.limit)} `}
          </strong>
          <span>
            {numbersDeclension(
              limits.cpu_cores?.limit || 0,
              [t('vm.params.core.1'), t('vm.params.core.2'), t('vm.params.core.5')],
            )}
          </span>
        </div>
        <div className="font-thin-body-s">
          <span>{`${t('vm.params.ram_2')}: `}</span>
          <strong>
            {`${numberFormatter(limits.ram?.limit)} `}
          </strong>
          <span>{t('common.gb')}</span>
        </div>
        <div className="font-thin-body-s">
          <span>{`${t('disk.disk_types.hdd_long')}: `}</span>
          <strong>
            {`${numberFormatter(limits.hdd_size?.limit)} `}
          </strong>
          <span>{t('common.gb')}</span>
        </div>
        <div className="font-thin-body-s">
          <span>{`${t('disk.disk_types.ssd_long')}: `}</span>
          <strong>
            {`${numberFormatter(limits.ssd_size?.limit)} `}
          </strong>
          <span>{t('common.gb')}</span>
        </div>

        <div className="font-thin-body-s">
          <span>
            {t('vm.params.external_ip')}
            {': '}
          </span>
          <strong>
            {`${numberFormatter(limits.public_ip?.limit)}`}
          </strong>
        </div>
        <div className="font-thin-body-s">
          <span>
            {t('network.local_network')}
            {': '}
          </span>
          <strong>
            {`${numberFormatter(limits.vlan?.limit)}`}
          </strong>
        </div>
        <div className="font-thin-body-s">
          <span>
            {t('backup.self_plural')}
            {': '}
          </span>
          <strong>
            {`${numberFormatter(limits.backup_quota?.limit)}`}
          </strong>
        </div>
        <div className="font-thin-body-s">
          <span>
            {t('os_template.title')}
            {': '}
          </span>
          <strong>
            {`${numberFormatter(limits.custom_image?.limit)}`}
          </strong>
        </div>
        {/* <div>
          <div className="font-thin-body-s">
            <span className="text-color-light">{`${t('vm.params.cpu')}: `}</span>
            <strong>
              {`${numberFormatter(limits.cpu_cores?.limit)} `}
            </strong>
            <span>
              {numbersDeclension(
                limits.cpu_cores?.limit || 0,
                [t('vm.params.core.1'), t('vm.params.core.2'), t('vm.params.core.5')],
              )}
            </span>
          </div>
          <div className="font-thin-body-s">
            <span>{`${t('vm.params.ram_2')}: `}</span>
            <strong>
              {`${numberFormatter(limits.ram?.limit)} `}
            </strong>
            <span>{t('common.gb')}</span>
          </div>
        </div>
        <div>
          <div className="font-thin-body-s">
            <span>{'HDD: '}</span>
            <strong>
              {`${numberFormatter(limits.hdd_size?.limit)} `}
            </strong>
            <span>{t('common.gb')}</span>
          </div>
          <div className="font-thin-body-s">
            <span>{'SSD: '}</span>
            <strong>
              {`${numberFormatter(limits.ssd_size?.limit)} `}
            </strong>
            <span>{t('common.gb')}</span>
          </div>
        </div>
        <div>

          <div className="font-thin-body-s">
            <span>
              {t('vm.params.external_ip')}
              {': '}
            </span>
            <strong>
              {`${numberFormatter(limits.public_ip?.limit)}`}
            </strong>
          </div>
          <div className="font-thin-body-s">
            <span>
              {t('network.local_networks')}
              {': '}
            </span>
            <strong>
              {`${numberFormatter(limits.vlan?.limit)}`}
            </strong>
          </div>
        </div>
        <div>
          <div className="font-thin-body-s">
            <span>
              {t('backup.self_plural')}
              {': '}
            </span>
            <strong>
              {`${numberFormatter(limits.backup?.limit)}`}
            </strong>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default ResourcesLimit;
