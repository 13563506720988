import { IVmNetworkDto } from 'store/network/types/api-bodies.types';
import { NetworkStatus } from 'store/network/types/enum';
import { INetwork } from 'store/network/types/network';

class VMNetwork implements INetwork {
  id: number;

  name: string | null;

  ip: string | null;

  mask: string | null;

  local: boolean;

  status?: NetworkStatus;

  linkedVmNames: string[] = [];

  mac: string | null = null;

  gateway: string | null;

  firewallGroup: Array<{
    name: string,
    id: number
  }> = [];

  constructor(dto: IVmNetworkDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.ip = dto.ip;
    this.mask = dto.mask;
    this.local = dto.local;
    this.mac = dto.mac || null;
    this.gateway = dto.gw;
  }
}

export default VMNetwork;
