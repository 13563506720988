import React from 'react';
import './style.scss';

import SideMenu from 'components/SideMenu';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header';
import useAsyncFetch from 'hooks/async-fetch';
import { useVMThunkDispatch } from 'hooks';
import { vmActions } from 'store/virtual-machine';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { UserRole } from 'store/user/types/state.types';
import NotificationContainer from 'components/NotificationContainer';

const Layout = () => {
  const user = useSelector((state: RootState) => state.user);

  const thunkVMDispatch = useVMThunkDispatch();

  const [fetchResources] = useAsyncFetch({
    asyncFunc: async () => {
      await Promise.all([
        thunkVMDispatch(vmActions.fetchParamLimits()),
        thunkVMDispatch(vmActions.fetchDiskSpeed()),
      ]);
    },
  });

  React.useEffect(() => {
    let timer: number | undefined;
    if (user.role === UserRole.Customer) {
      fetchResources();
      timer = window.setInterval(fetchResources, 20000);
    }
    return () => clearInterval(timer);
  }, [user]);

  return (
    <div className="main-layout">
      <Header />
      <div className="main-layout__main">
        <SideMenu />
        <Outlet />
      </div>
      <NotificationContainer />
    </div>
  );
};
export default Layout;
