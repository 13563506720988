import PasswordInput from 'components/UI_Kit/Form/PasswordInput';
import { useFormik } from 'formik';
import { useAppSelector, useUserThunkDispatch } from 'hooks';
import useAsyncFetch from 'hooks/async-fetch';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from 'store/user';
import { User } from 'store/user/types';
import { IChangePasswordBody } from 'store/user/types/api-bodies.types';
import { IChangePasswordForm } from 'store/user/types/forms.types';
import { UserRole } from 'store/user/types/state.types';
import * as yup from 'yup';

const ResetPasswordForm = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const thunkUserDispatch = useUserThunkDispatch();
  const user = useAppSelector((state) => state.user);

  const handleLogOut = () => {
    dispatch(userActions.clearUser());
    navigate('/');
  };

  const [handleChangePass, loading] = useAsyncFetch({
    async asyncFunc(form: IChangePasswordForm) {
      const body: IChangePasswordBody = {
        password: user.tempPass as string,
        newPassword: form.newPass,
        login: user.email,
      };
      const res = await thunkUserDispatch(userActions.changePassword(body));
      return res;
    },
    successHandler(res) {
      if (res instanceof User) {
        if (res.role === UserRole.Customer) {
          navigate('cabinet', { replace: true });
        }
        if (res.role === UserRole.Admin) {
          navigate('admin', { replace: true });
        }
      }
    },

    errorHandler(_, errMessage) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setFieldError('newPassConfirm', errMessage);
    },

  });

  const schema = React.useMemo(() => yup.object().shape({
    newPass: yup.string()
      .required(t('form.error.required'))
      .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+={}[\]|:;"'<>,.?/])[A-Za-z\d~!@#$%^&*()_\-+={}[\]|:;"'<>,.?/]{8,}$/g, t('form.error.password_weak'))
      .test('not_old_pass', t('form.error.pass_must_be_new'), (value) => value !== user.tempPass),
    newPassConfirm: yup.string().required(t('form.error.required')).oneOf([yup.ref('newPass'), null], t('form.error.confirm')),
  }), [t]);

  const initialForm = React.useMemo<IChangePasswordForm>(() => ({
    newPass: '',
    newPassConfirm: '',
  }), []);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldError,
  } = useFormik({
    validationSchema: schema,
    onSubmit: handleChangePass,
    initialValues: initialForm,
    validateOnChange: false,
  });
  return (
    <Form noValidate onSubmit={handleSubmit} id="account-form" autoComplete="off">
      <Form.Group controlId="newPass" className="mb-3">
        <Form.Label className="font-bold-body-m">{t('form.field.password')}</Form.Label>
        <PasswordInput
          name="newPass"
          autoComplete="new-password"
          value={values?.newPass}
          error={errors.newPass}
          isInvalid={touched.newPass && !!errors.newPass}
          onChange={handleChange}
        />
        <Form.Text>{t('auth.password_requirements')}</Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.newPass}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="newPassConfirm" className="mb-3">
        <Form.Label className="font-bold-body-m">{t('form.field.confirm_password')}</Form.Label>
        <PasswordInput
          name="newPassConfirm"
          autoComplete="new-password"
          value={values?.newPassConfirm}
          onChange={handleChange}
          error={errors.newPassConfirm}
          isInvalid={touched.newPassConfirm && !!errors.newPassConfirm}
        />
      </Form.Group>
      <Form.Group className="row">
        <div className="col">
          <Button
            className="w-100"
            disabled={loading}
            variant="primary"
            size="lg"
            onClick={handleLogOut}
          >
            {t('auth.signout')}
          </Button>
        </div>
        <div className="col">
          <Button
            className="w-100"
            disabled={loading}
            type="submit"
            size="lg"
            form="account-form"
            variant="primary"
          >
            {t('common.save')}
          </Button>
        </div>
      </Form.Group>
    </Form>
  );
};

export default ResetPasswordForm;
