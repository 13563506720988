import { updateClassState } from 'store/reducer.helpers';
import { User } from 'store/user/types';
import { IRegion } from 'store/user/types/state.types';

export function setTokenReducer(state: User, payload: string) {
  const user = updateClassState(state, state);
  user.token = payload;
  return user;
}

export function setLoading(state: User, payload: boolean) {
  const user = updateClassState(state, state);
  user.loading = payload;
  return user;
}

export function clearUserReducer() {
  const user = new User();
  user.loading = false;
  localStorage.removeItem('PD-AT');
  return user;
}

export function setRegionHelper(state: User, payload: IRegion) {
  const user = updateClassState(state, state);
  user.region = payload;
  return user;
}
export function setRegionsHelper(state: User, payload: IRegion[]) {
  const user = updateClassState(state, state);
  user.regions = payload;
  return user;
}
