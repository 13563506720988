import i18n from 'services/i18n';

export enum UserRole {
  Guest,
  Customer,
  Admin,
}

export enum UserStatus {
  New,
  Active,
  Blocked,
  Deleted,
}

export const userRoles = {
  [UserRole.Admin]: i18n.t('user.role.admin'),
  [UserRole.Customer]: i18n.t('user.role.guest'),
  [UserRole.Guest]: i18n.t('user.role.guest'),
};

export enum ActionTypes {
  SET_USER = 'user:change',
  SET_TOKEN = 'user:setToken',
  SET_LOADING = 'user:set-loading',
  CLEAR = 'user:clear',
  SET_REGION = 'user:set-region',
  SET_REGIONS = 'user:set-regions',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export const GENDERS = {
  [Gender.Male]: i18n.t('profile.personal_info.male'),
  [Gender.Female]: i18n.t('profile.personal_info.female'),
};

export interface IRegion {
  id: number
  name: string
  regionId?: string
}
