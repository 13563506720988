import React from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/profile.svg';
import { User } from 'store/user/types';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import './UserCard.style.scss';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrows/chevron-down.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/security.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/exit.svg';
import { ReactComponent as LangIcon } from 'assets/icons/language.svg';
import ButtonWithConfirm from 'components/UI_Kit/Button/ButtonWithConfirm';
import { useAppDispatch } from 'hooks';
import { userActions } from 'store/user';
import authService from 'services/api/auth-service';

interface IUserCardProps {
  user: User
  className?: string
}

type CustomToggleProps = {
  user: User;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
};

const CustomToggle = React.forwardRef((
  props: CustomToggleProps,
  ref: React.Ref<HTMLButtonElement>,
) => (
  <button
    ref={ref}
    type="button"
    className="user-card__toggle"
    onClick={(e) => {
      e.preventDefault();
      props.onClick(e);
    }}
  >
    <div className="p-2 rounded-circle bg-primary me-2 text-white flex-shrink-0">
      <UserIcon className="user-icon" />
    </div>
    <div className="me-2">
      <div className="fs-6">{props.user.fullName}</div>
      <div className="opacity-50 fs-6">{props.user.email}</div>
    </div>
    <ArrowIcon className="user-card__arrow" />
  </button>
));

type CustomMenuProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  labeledBy?: string;
};

const CustomMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => (
    <div
      ref={ref}
      style={props.style}
      className={`${props.className}`}
      aria-labelledby={props.labeledBy}
    >
      <ul className="list-unstyled">
        {props.children}
      </ul>
    </div>
  ),
);

const UserCard: React.FC<IUserCardProps> = ({ className, user }) => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogOut = async () => {
    dispatch(userActions.clearUser());
    navigate('/');
  };
  const handleSelect = (value: string | null) => {
    if (value) {
      i18n.changeLanguage(value);
      localStorage.setItem('pdlang', value);
      authService.setLanguage(value);
    }
  };

  React.useEffect(() => {
    if (user.language && user.language !== i18n.language) {
      i18n.changeLanguage(user.language);
      localStorage.setItem('pdlang', user.language);
    }

    if (user.token && user.language === null) {
      authService.setLanguage(i18n.language);
    }
  }, [user]);
  return (
    <Dropdown className={classNames('user-card', className)}>
      <Dropdown.Toggle
        user={user}
        as={CustomToggle}
      />
      <Dropdown.Menu renderOnMount align="start" as={CustomMenu}>
        <Dropdown.Item as="li" className="user-card__link">
          <Link to="/cabinet/profile/edit">
            <UserIcon />
            <span>{t('profile.self')}</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="li" className="user-card__link">
          <Link to="/cabinet/settings/log">
            <SecurityIcon />
            <span>{t('security.self')}</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as="li" className="user-card__link">

          {
          i18n.language === 'ru' ? (
            <button type="button" className="btn btn-styleless" onClick={() => handleSelect('en')}>
              <LangIcon />
              <span>{t('langs_long.en')}</span>
            </button>
          ) : (
            <button type="button" className="btn btn-styleless" onClick={() => handleSelect('ru')}>
              <LangIcon />
              <span>{t('langs_long.ru')}</span>
            </button>
          )
          }
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className="user-card__link">

          <ButtonWithConfirm
            header={t('auth.logout_confirm')}
            onConfirm={handleLogOut}
            okTitle={t('common.yes')}
          >
            <LogOutIcon />
            <span>{t('auth.signout')}</span>
          </ButtonWithConfirm>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserCard;
