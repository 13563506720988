import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { isJsonArray } from 'services/api/utils';

export type TableFilters = {
  [key: string]: number | string | boolean | Array<number | string>;
};

export type TableSorting = {
  sortBy: string | null;
  order: 'desc' | 'asc';
};

export type TablePagination = {
  page: number;
  pageSize: number;
  total: number;
};

export type TableState = {
  pagination: TablePagination;
  sorting: TableSorting;
  filters: TableFilters;
  reloadTrigger: boolean
};

export type PaginatedList<T> = {
  data: T[];
  total?: number;
};

export type TableChangeHandler = (value: Partial<TableState>) => void;

export function toQueryParams({ pagination, sorting, filters }: Partial<TableState>) {
  const params: { [key: string]: string | number | boolean } = {};
  if (pagination) {
    params.page = pagination.page - 1;
    params.size = pagination.pageSize;
  }

  if (sorting && sorting.sortBy) {
    params.sort_by = sorting.sortBy;
    params.order = sorting.order;
  }

  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined && filters[key] !== 'null') {
        const value = filters[key];
        if (Array.isArray(value)) {
          params[key] = JSON.stringify(value);
        } else if (value) {
          params[key] = value.toString();
        }
      }
    });
  }
  return params;
}

export type TableQueryParams = ReturnType<typeof toQueryParams>;

/**
 * Table state pagination, sorting and filters
 * @param params Initial table state
 * @returns state of the current pagination, sorting and filters
 */
const useTableParams = (
  params: Partial<TableState>,
): [
    TableState & { queryParams: TableQueryParams },
    TableChangeHandler,
    (total: number) => void,
  ] => {
  const initPagination = params.pagination || { page: 1, pageSize: 20, total: 0 };

  const initSorting: TableSorting = params.sorting || {
    sortBy: null,
    order: 'desc',
  };
  const initFilters = params.filters || {};
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.forEach((value, key) => {
    switch (key) {
      case 'page':
        initPagination.page = Number(value);
        break;
      case 'pageSize':
        initPagination.pageSize = Number(value);
        break;
      case 'sort':
        [initSorting.sortBy, initSorting.order] = value.split('_') as [string, 'desc' | 'asc'];
        break;
      // in case to keep active tab on reload and store tab key in search params
      case 'tab':
        break;
      default:
        if (isJsonArray(value)) {
          initFilters[key] = JSON.parse(value) as Array<string | number>;
        } else {
          initFilters[key] = value;
        }
        break;
    }
  });
  const [pagination, setPagination] = React.useState<TablePagination>(initPagination);
  const [sorting, setSorting] = React.useState<TableSorting>(initSorting);
  const [filters, setFilters] = React.useState<TableFilters>({ ...initFilters });
  const [reloadTrigger, setReloadTrigger] = React.useState(false);

  const handleTableChange: TableChangeHandler = React.useCallback(
    (tableParams, preventReload?: boolean) => {
      setSearchParams((_params) => {
        const newParams: URLSearchParams = _params;

        if (tableParams.pagination) {
          setPagination({ ...tableParams.pagination });
          newParams.set('page', tableParams.pagination.page.toString());
          newParams.set('pageSize', tableParams.pagination.pageSize.toString());
        }

        if (tableParams.sorting) {
          setSorting(tableParams.sorting);
          if (tableParams.sorting.sortBy) {
            newParams.set('sort', `${tableParams.sorting.sortBy}_${tableParams.sorting.order}`);
          } else {
            newParams.delete('sorting');
          }
        }

        if (tableParams.filters) {
          setFilters(tableParams.filters);
          Object.entries(tableParams.filters).forEach(([key, val]) => {
            if (Array.isArray(val)) {
              newParams.set(key, JSON.stringify(val));
            } else if (val) {
              newParams.set(key, val.toString());
            }
          });
        }
        return newParams;
      });

      if (!preventReload) {
        setReloadTrigger((value) => !value);
      }
    },
    [sorting, filters, pagination, reloadTrigger],
  );

  const setTotal = React.useCallback((total: number) => {
    setPagination((_pagination) => ({ ..._pagination, total }));
  }, []);

  return [
    {
      pagination,
      sorting,
      filters,
      reloadTrigger,
      queryParams: toQueryParams({ pagination, sorting, filters }),
    },
    handleTableChange,
    setTotal,
  ];
};

export default useTableParams;
