/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from 'axios';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useUserThunkDispatch } from 'hooks';
import useAsyncFetch from 'hooks/async-fetch';
import React from 'react';
import { Button, Form, FormText } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { userActions } from 'store/user';
import { User } from 'store/user/types';
import { ILoginBody } from 'store/user/types/api-bodies.types';
import { ILoginForm } from 'store/user/types/forms.types';
import { UserRole } from 'store/user/types/state.types';
import * as yup from 'yup';

const LoginForm: React.FC = () => {
  const thunkUserDispatch = useUserThunkDispatch();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [blockedMessage, setBlockedMessage] = React.useState<[string, string] | null>(null);

  const schema = React.useMemo(() => yup.object().shape({
    email: yup.string().email().required(t('form.error.required')).lowercase(),
    password: yup.string().required(t('form.error.required')),
  }), [t]);

  const [handleLogin, loading] = useAsyncFetch({
    asyncFunc: async (form: ILoginForm) => {
      const body: ILoginBody = {
        login: form.email,
        password: form.password,
      };
      setBlockedMessage(null);
      const res = await thunkUserDispatch(userActions.signIn(body));
      return res;
    },
    successHandler: (res) => {
      if (res instanceof User) {
        if (res.role === UserRole.Customer) {
          navigate('cabinet', { replace: true });
        }
        if (res.role === UserRole.Admin) {
          navigate('admin', { replace: true });
        }
      } else if (res?.errorType === 'WrongLoginPassword') {
        setFieldError('password', `${t('form.error.invalid_credentials')}, ${t('form.error.login_attempts_left')}: ${res.message}`);
      } else if (res?.errorType === 'LimitOfWrongPassExceeded' || res?.errorType === 'UserTemporaryBlocked') {
        const errorString = `${t('form.error.user_blocked_until')} ${dayjs(res.message).format('HH:mm')}`;
        setBlockedMessage(errorString.split('{linebreak}') as [string, string]);
      }
    },
    errorHandler: (error, errMessage) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400 || error.response?.status === 403) {
          if (error.response.data.data === 'WrongLoginPassword') {
            setFieldError('password', `${t('form.error.invalid_credentials')}, ${t('form.error.login_attempts_left')}: ${error.response.data.errMessage}`);
          } else if (error.response.data.data === 'LimitOfWrongPassExceeded' || error.response.data.data === 'UserTemporaryBlocked') {
            const errorString = `${t('form.error.user_blocked_until')} ${dayjs(error.response.data.errMessage).format('HH:mm')}`;
            setBlockedMessage(errorString.split('{linebreak}') as [string, string]);
          } else {
            setFieldError('password', t('form.error.invalid_credentials'));
          }
        } else {
          // something wrong with server on no connection
          setFieldError('password', errMessage);
        }
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldError,
  } = useFormik({
    validationSchema: schema,
    onSubmit: handleLogin,
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group className="mb-4 pb-2" controlId="formik-email">
        <Form.Label className="font-bold-body-m">{t('form.field.email')}</Form.Label>
        <Form.Control
          type="text"
          name="email"
          value={values.email}
              // onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={touched.email && !!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password}
        </Form.Control.Feedback>

      </Form.Group>
      <Form.Group className="mb-4 pb-2" controlId="formBasicPassword">
        <Form.Label className="font-bold-body-m">{t('form.field.password')}</Form.Label>
        <Form.Control
          type="password"
          name="password"
          autoComplete="on"
          value={values.password}
          onChange={handleChange}
              // isValid={touched.password && !errors.password}
          isInvalid={touched.password && !!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password}
        </Form.Control.Feedback>
        {blockedMessage && (
          <>
            <FormText className="invalid-feedback d-block">{blockedMessage[0]}</FormText>
            <FormText className="invalid-feedback d-block mt-0">{blockedMessage[1]}</FormText>
          </>
        )}
      </Form.Group>
      <Form.Group className="">
        <Button className="w-100" size="lg" disabled={loading} type="submit" variant="primary">{t('form.action.login')}</Button>
      </Form.Group>
    </Form>
  );
};
export default LoginForm;
