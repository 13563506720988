import { IResponse } from 'services/api/axios';

export enum ActionTypes {
  SET_ERRORS = 'api-errors:set-errors',
}

export interface IApiError {
  eng: string,
  id: number,
  code: string,
  ru: string
}

export interface IApiErrorDtoRes extends IResponse {
  data: IApiError[]
}

export interface IApiErrorsList {
  [key: string]: {
    ru: string,
    en: string
  }
}
