import { Axios } from 'axios';
import { IUserSettings } from 'components/settings/types/user-settings';
import apiClient from 'services/api/axios';
import {
  IClientUserCreateDto,
  IClientUserEmailDto, IClientUserResDto,
  IClientUserStatusChangeReqDto,
} from 'store/user/types/client-user';

class UserService {
  api: Axios;

  constructor(api: Axios) {
    this.api = api;
  }

  getUsers() {
    return this.api.get<IClientUserResDto>('userPanel/childUserList');
  }

  createUser(dto: IClientUserCreateDto) {
    return this.api.post<IClientUserCreateDto>('userPanel/registerChildUser', dto);
  }

  updateUserEmail(dto: IClientUserEmailDto) {
    return this.api.post<IClientUserCreateDto>('notification/status/change', undefined, { params: dto });
  }

  confirmUserEmail(token: string) {
    return this.api.post(`notification/status/confirm/${token}`);
  }

  changeUserStatus(dto: IClientUserStatusChangeReqDto) {
    return this.api.post<{ data: string | {} }>('userPanel/setUserStatus', dto);
  }

  updateUserSettings(dto: IUserSettings) {
    return this.api.put(`user-settings/${dto.id}`, dto);
  }
}

export default new UserService(apiClient);
