import { Axios } from 'axios';
import {
  IBackupCreateDto, IBackupLimitsDto, IBackupListRes,
  IBackupScheduleDto, IBackupStatusCodeRes,
  IBackupsScheduledRes, IScheduledBackupLimitDto,
} from 'components/Backup/types/dtos';
import apiClient from 'services/api/axios';
import { IBackupQuotaReqParams } from 'store/virtual-machine/types/api-bodies.types';

class BackupService {
  api: Axios;

  constructor(api: Axios) {
    this.api = api;
  }

  getBackupsList(vmId: string) {
    return this.api.get<IBackupListRes>(`panel/vm/${vmId}/backup/list`);
  }

  getAllBackups() {
    return this.api.get<IBackupListRes>('panel/backups/list');
  }

  getLimits(params: IBackupQuotaReqParams) {
    return this.api.get<IBackupLimitsDto>('panel/backup/quotas', { params });
  }

  createBackup(vmId: string, dto: IBackupCreateDto) {
    return this.api.post<IBackupStatusCodeRes>(`panel/vm/${vmId}/backup`, dto);
  }

  restoreBackup(vmId: string, backupId: number) {
    return this.api.post(`panel/vm/${vmId}/backup/${backupId}/restore`);
  }

  getBackupCreateStatus(vmId: string) {
    return this.api.get<IBackupStatusCodeRes>(`panel/vm/backupTaskStatus/${vmId}`);
  }

  getBackupRestoreStatus(vmId: string) {
    return this.api.get<IBackupStatusCodeRes>(`panel/vm/backupRestoreStatus/${vmId}`);
  }

  deleteBackup(backupId: number) {
    return this.api.post(`panel/vm/backups/${backupId}/delete`);
  }

  getBackupScheduled(vmId: string) {
    return this.api.get<IBackupsScheduledRes>(`panel/vm/${vmId}/backupScheduled/list`);
  }

  scheduleBackup(vmId: string, dto: IBackupScheduleDto) {
    return this.api.post(`panel/vm/${vmId}/backupScheduled`, dto);
  }

  deleteBackupScheduled(vmId: string, backupId: number) {
    return this.api.post(`panel/vm/${vmId}/backupScheduled/${backupId}/delete`);
  }

  setScheduledLimit(limit: number | string) {
    return this.api.post(`/panel/limits/setScheduledBackupsLimit/${limit}`);
  }

  getScheduledBackupLimit() {
    return this.api.get<IScheduledBackupLimitDto>('/panel/limits/scheduledBackups');
  }
}

export default new BackupService(apiClient);
