import { IUserAuthDto } from 'store/user/types/api-bodies.types';
import { IRegion, UserRole, UserStatus } from 'store/user/types/state.types';

class User {
  id: number = 0;

  token: string | null = null;

  role: UserRole = UserRole.Guest;

  fullName: string = '';

  email: string = '';

  status: UserStatus = UserStatus.New;

  loading: boolean = true;

  tempPass?: string;

  tempLogin?: string;

  region: IRegion | null = null;

  regions: IRegion[] = [];

  language: string | null = null;

  state: 'login' | '2fa' | 'expired' | 'success' = 'login';

  parentUserId: number | null = null;

  constructor(body?: IUserAuthDto) {
    if (!body) return;
    this.id = body.user.id;
    this.role = body.user.roleId;
    this.token = body.accessToken || null;
    this.fullName = body.user.fullName;
    this.email = body.user.login;
    this.status = body.user.status;
    this.region = body.user.currentRegion;
    this.language = body.user.language;
    this.state = 'success';
    this.parentUserId = body.user.parentUserId;
  }

  get isMain() {
    return this.parentUserId === this.id;
  }
}

export default User;
