import i18n from 'services/i18n';
import { v4 as uuidv4 } from 'uuid';

export enum NotifyVariant {
  Success = 'success',
  Error = 'danger',
  Info = 'info',
  Warning = 'warning',
  Primary = 'primary',
}

const notificationDefaultMessages = {
  [NotifyVariant.Success]: i18n.t('notification.success'),
  [NotifyVariant.Error]: i18n.t('notification.error'),
  [NotifyVariant.Info]: i18n.t('notification.info'),
  [NotifyVariant.Warning]: i18n.t('notification.warning'),
  [NotifyVariant.Primary]: i18n.t('notification.info'),
};

export interface INotificationParams {
  variant: NotifyVariant;
  title?: string;
  message?: string;
}

export class Notification {
  id: string;

  visible: boolean;

  variant: NotifyVariant;

  title: string;

  message: string;

  constructor(params: INotificationParams) {
    this.id = uuidv4();
    this.visible = true;
    this.variant = params.variant;
    this.title = params.title || notificationDefaultMessages[params.variant];
    this.message = params.message || notificationDefaultMessages[params.variant];
  }
}

export enum ActionTypes {
  ADD = 'notification:add',
  REMOVE = 'notification:remove',
}
