import { Axios } from 'axios';
import apiClient, { IResponse } from 'services/api/axios';
import { ITableQueryParams, toQueryParams } from 'services/api/utils';
import { IValidateIpReqBody, IValidateIpResBody } from 'store/user/types/api-bodies.types';
import {
  IConsoleBody,
  ICostResBody,
  IDiskAddBody,
  IDiskIncreaseBody,
  IIDiskSpeedsResDto,
  INetworkEditBody,
  IOSTypesRes,
  IParamLimitsResBody,
  IResetVmRootPasswordRes,
  ISshResDto,
  ISshUploadDto,
  IVMCreateReqBody, IVMEditReqBody, IVMListResBody, IVMListUnsyncResBody,
  IVMResBody, IVMShortListDto, IVMTemplateResBody,
} from 'store/virtual-machine/types/api-bodies.types';
import { VMStateAction } from 'store/virtual-machine/types/state.types';

class VMService {
  api: Axios;

  constructor(api: Axios) {
    this.api = api;
  }

  getVMList() {
    return this.api.get<IVMListResBody>('panel/vm/list');
  }

  getVMListUnsync() {
    return this.api.get<IVMListUnsyncResBody>('panel/getUnSyncUserVms');
  }

  getVMListShort(params: ITableQueryParams) {
    return this.api.get<IVMShortListDto>('panel/vm/list/simplified', { params: toQueryParams(params) });
  }

  getVMById(id: string) {
    return this.api.get<IVMResBody>(`panel/vm/${id}`);
  }

  getTemplates() {
    return this.api.get<IVMTemplateResBody>('panel/vm/templates');
  }

  getOSTypes() {
    return this.api.get<IOSTypesRes>('panel/getAllVmOSTypes');
  }

  getVMParamLimits(vmTemplateId?: number) {
    return this.api.get<IParamLimitsResBody>('panel/vm/resourceMetaData', { params: { vmTemplateId } });
  }

  createVM(body: IVMCreateReqBody) {
    return this.api.post<IVMResBody>('panel/vm/prepare', body);
  }

  deleteVM(id: string | number, name: string, isDeleteIp: boolean) {
    return this.api.post(`/panel/vm/${id}/delete?vmName=${name}`, isDeleteIp, { headers: { 'Content-type': 'application/json' } });
  }

  editVMConfig(id: string, body: IVMEditReqBody) {
    return this.api.post<IVMResBody>(`panel/vm/${id}/edit`, body);
  }

  calculateCost(body: IVMCreateReqBody) {
    return this.api.post<ICostResBody>('panel/vm/calculateCost', body);
  }

  validateLocalIp(body: IValidateIpReqBody) {
    return this.api.post<IValidateIpResBody>('/panel/validateIpAddress', body);
  }

  addDisk(vmId: string, needReboot: boolean, body: IDiskAddBody) {
    return this.api.post<IVMResBody>(`/panel/vm/${vmId}/disks/add?need_reboot=${needReboot ? 'true' : 'false'}`, body);
  }

  increaseDisk(vmId: string, diskId: number, needReboot: boolean, body: IDiskIncreaseBody) {
    return this.api.post<IResponse>(`/panel/vm/${vmId}/disks/${diskId}/increase?need_reboot=${needReboot ? 'true' : 'false'}`, body);
  }

  deleteDisk(vmId: string, diskId: number) {
    return this.api.post<IResponse>(`/panel/vm/${vmId}/disks/${diskId}/delete`);
  }

  asBootDisk(vmId: string, diskId: number) {
    return this.api.post<IResponse>(`/panel/vm/${vmId}/disks/${diskId}/asBootDisk`);
  }

  editNetwork(vmId: string, networkId: number, body: INetworkEditBody) {
    return this.api.post<IResponse>(`/panel/vm/${vmId}/nets/${networkId}/edit`, body);
  }

  runVM(id: string) {
    return this.api.post<IResponse>(`/panel/vm/${id}/start`);
  }

  stopVM(id: string) {
    return this.api.post<IResponse>(`/panel/vm/${id}/stop`);
  }

  setVMStatus(id: string, action: VMStateAction) {
    const body = {
      vmAction: action,
    };
    return this.api.post(`/panel/vm/${id}/changeState`, body);
  }

  getConsole(vmId: string) {
    return this.api.get<IConsoleBody>(`/panel/getVMConsole/${vmId}`);
  }

  getPrivateKey(vmId: string) {
    return this.api.get<ISshResDto>('/panel/ssh/privateKey', { params: { vmId } });
  }

  getPublicKey(vmId: string) {
    return this.api.get<ISshResDto>('/panel/ssh/publicKey', { params: { vmId } });
  }

  uploadPublicKey(dto: ISshUploadDto, vmId: string) {
    return this.api.post('panel/ssh/upload', dto, { params: { vmId } });
  }

  deleteKeys(vmId: string) {
    return this.api.post('/panel/ssh/delete', undefined, { params: { vmId } });
  }

  fetchDiskSpeed() {
    return this.api.get<IIDiskSpeedsResDto>('/panel/getGeneralDiskLimits');
  }

  resetRootPass(vmId: string) {
    return this.api.post<IResetVmRootPasswordRes>(`os_templates/generated/password/${vmId}`);
  }
}

export default new VMService(apiClient);
