import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import notificationService from 'services/api/customer/notification-service';
import { RootState } from 'store';
import { ActionTypes, IAppNotification } from 'store/app-notifications/types';

// eslint-disable-next-line import/prefer-default-export
export const setNotifications = (payload: IAppNotification[]): AnyAction => ({
  type: ActionTypes.SET_NOTIFICATIONS,
  payload,
});

export const setUnreadCount = (payload: number): AnyAction => ({
  type: ActionTypes.SET_UNREAD_COUNT,
  payload,
});

export const setReadById = (payload: number): AnyAction => ({
  type: ActionTypes.SET_READ,
  payload,
});

export type ThunkAppNotificationDispatch = ThunkDispatch<RootState, void, AnyAction>;

export const fetchNotifications = () => async (dispatch: ThunkAppNotificationDispatch) => {
  const { data } = await notificationService.getNotifications();
  if (data.data[0]) {
    dispatch(setUnreadCount(data.data[0].cntWasRead));
    dispatch(setNotifications(data.data[0].list));
  }
};

export const setRead = (ids: number[]) => async () => {
  const reqArr = ids.map((id) => notificationService.setRead(id));
  await Promise.all(reqArr);
};
