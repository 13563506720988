import React from 'react';
import { Form } from 'react-bootstrap';
import { useIMask } from 'react-imask';

interface IMaskedFormControlProps {
  maskConfig: Parameters<typeof useIMask>[0];
  type: string;
  className?: string;
  name?: string;
  inputValue: string | undefined;
  isValid?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  defaultValue?: string | number;
  onChange: (value: string | number) => void;
}

const MaskedFormControl = ({
  maskConfig, type, className, name,
  disabled, isInvalid, isValid, autoFocus, inputValue, defaultValue, placeholder, onChange,
}: IMaskedFormControlProps) => {
  const handleChange = ((value: string, _mask: any) => {
    onChange(_mask.unmaskedValue);
  });

  const {
    ref,
    setTypedValue,
  } = useIMask(maskConfig, {
    onAccept: ((value: string, _mask: any) => {
      onChange(_mask.unmaskedValue);
    }),
    onComplete: handleChange,
  });

  React.useEffect(() => {
    if (inputValue) {
      setTypedValue(inputValue);
    }
  }, [inputValue]);

  // console.log(setTypedValue, inputValue);

  return (
    <Form.Control
      type={type}
      className={className}
      name={name}
      ref={ref as React.RefObject<HTMLInputElement>}
      disabled={disabled}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      isValid={isValid}
      isInvalid={isInvalid}
      placeholder={placeholder}
    />
  );
};

MaskedFormControl.defaultProps = {
  className: '',
  name: '',
  isValid: undefined,
  isInvalid: undefined,
  disabled: undefined,
  defaultValue: undefined,
  placeholder: '',
};

export default MaskedFormControl;
